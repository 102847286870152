<template>
  <header class="page__header">
    <div class="page__header-container">
      <LogoPic class="page__header-logo" />
      <nav class="page__navigation">
        <router-link
          v-for="(item, i) of pages"
          :key="`nav-${i}`"
          :to="`/${item}`"
          class="page__navigation-item"
        >
          <span>{{ item }}</span>
        </router-link>
      </nav>
      <LogoInsupen class="page__header-insupen" />
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex'
import LogoPic from '@/components/LogoPic'
import LogoInsupen from '@/components/LogoInsupen'

export default {
  name: 'PageHeader',

  computed: {
    ...mapState({
      pages: (state) => state.application.pages,
    }),
  },

  components: {
    LogoPic,
    LogoInsupen,
  },
}
</script>

<style lang="scss">
.page {
  &__header {
    position: relative;
    z-index: 99;
    height: 5rem;
    background: -var(--color-bg);
    box-shadow: 0 4px 13px rgba(0, 0, 0, 0.07);
    position: relative;

    @media screen and (max-width: 460px) {
      height: 60px;
    }

    &-container {
      width: 100%;
      max-width: var(--max-width);
      padding: 0 2rem;
      height: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 460px) {
        padding: 0 1rem;
      }
    }

    &-logo {
      height: 7rem;
      position: relative;
      top: calc(50% - 4px);
      border: 2px solid var(--color-bg);
      border-radius: 100%;
      box-shadow: 0 4px 13px rgba(0, 0, 0, 0.07);

      @media screen and (max-width: 460px) {
        height: 4.5rem;
        width: 4.5rem;
      }
    }
    &-insupen {
      height: 2.5rem;

      @media screen and (max-width: 460px) {
        display: none;
      }
    }
  }

  &__navigation {
    display: flex;
    align-items: center;

    a {
      text-transform: uppercase;
      font-weight: 500;
      color: var(--color-main);
      text-decoration: none;
      padding: 3px 20px;
      transition: var(--transition);

      @media screen and (max-width: 460px) {
        padding: 3px 8px;
        font-size: 14px;
      }

      span {
        display: block;
        transition: var(--transition);
        border-bottom: 2px solid transparent;
      }

      &.router-link-exact-active {
        font-weight: 600;

        span {
          border-color: var(--color-main);
        }
      }
    }
  }
}
</style>
