<template>
  <footer class="page__footer">
    <div class="page__footer-container">
      <div class="page__footer-logo">
        <a href="https://picsolution.pl" target="_blank">
          <LogoPic />
        </a>
      </div>
      <div class="page__footer-packshot">
        <span>Dystrybutor igieł PIC Insupen Original</span>
        <a href="https://picsolution.pl/produkty/cukrzyca" target="_blank">
          <img
            :src="require('@/assets/pic-packshot.png')"
            alt="PIC Insupen Original"
          />
        </a>
      </div>

      <div class="page__footer-contact">
        <p class="page__footer-contact-info">
          Podmiot prowadzący reklamę:<br />
          <strong>SIROWA Poland Sp. z o.o.</strong>
          z siedzibą w Warszawie<br />
          ul. Poselska 11<br />
          03-931 Warszawa<br />
          <br />
          Wytwórca:<br />
          PIKDARE S.p.A.,<br />
          Via Saldarini Catelli,<br />
          10 22070 Casnate con Bernate (CO), Włochy
        </p>
        <p class="page__footer-contact-contacts">
          <span>+48 22 518 58 09</span>
          <span>+48 666 026 957</span>
          <a href="mailto:Insupen.refundacja@sirowa.com"
            >Insupen.refundacja@sirowa.com</a
          >
        </p>
      </div>
      <div class="page__footer-links">
        <router-link to="/polityka-prywatnosci"
          >Polityka prywatności</router-link
        >
        <router-link to="/regulamin">Regulamin</router-link>
      </div>
    </div>

    <div class="medical--info">
      <div>
        To jest wyrób medyczny. Używaj go zgodnie z instrukcją używania lub
        etykietą.
      </div>
    </div>
  </footer>
</template>

<script>
import LogoPic from '@/components/LogoPic'

export default {
  name: 'PageFooter',

  components: {
    LogoPic,
  },

  data() {
    return {
      ktoMaLek:
        'https://ktoMalek.pl/leon-www/rezerwacjeLekow/KLEK/gdzieKupieLeki?&ids=9098517&typ=B',
    }
  },
}
</script>

<style lang="scss">
.medical--info {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: var(--color-main);
  color: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
  padding: 0.125rem;
  font-size: 2.45vw;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  height: 10vh;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    font-size: 18px;
    gap: 1rem;
  }
}

.page__footer {
  background: var(--color-bg-alt);
  padding: 5rem 3rem 8rem;

  @media screen and (max-width: 480px) {
    padding: 3rem 2.5rem 7rem;
  }

  &-container {
    width: 100%;
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;

    @media screen and (max-width: 480px) {
      flex-direction: column;
    }

    &::before {
      content: 'PIC_EP_LP_03.2022';
      font-family: var(--font-family);
      position: absolute;
      left: 0;
      bottom: 5rem;
      transform: rotate(-90deg);
      font-size: 10px;
      transform-origin: 0 0;
      opacity: 0.7;

      @media screen and (max-width: 480px) {
        bottom: -0.5rem;
        left: -2rem;
      }
    }
  }

  &-logo {
    flex: 1 100%;
    max-width: 20%;
    position: relative;
    z-index: 5;

    @media screen and (max-width: 480px) {
      max-width: 100%;
      justify-content: center;
      display: flex;
    }

    svg {
      display: block;
      width: 10rem;
      max-width: 10rem;

      @media screen and (max-width: 480px) {
        width: 6rem;
        max-width: 6rem;
        margin-bottom: 2rem;
      }
    }
  }
  &-packshot {
    flex: 1 100%;
    max-width: 50%;
    position: relative;
    z-index: 4;

    @media screen and (max-width: 480px) {
      max-width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    span {
      font-weight: 600;
      font-size: 1rem;
      display: block;
      margin-bottom: 1rem;
    }

    a {
      display: block;
      text-decoration: none;
      margin: 3rem 9rem -2rem -10rem;

      @media screen and (max-width: 480px) {
        margin: 0rem 1rem 2rem -5rem;
      }
    }
  }
  &-contact {
    flex: 1 100%;
    max-width: 30%;
    font-size: 0.842rem;
    padding-left: 3rem;

    @media screen and (max-width: 480px) {
      max-width: 100%;
      padding: 3rem 0 0;
      font-size: 1rem;
    }

    p {
      margin: 0 0 2rem;
      line-height: 1.7;

      strong,
      span {
        display: block;
      }

      span {
        font-weight: 600;
      }

      a {
        font-weight: 600;
        color: var(--color-main);
        text-decoration: none;
        padding-bottom: 0px;
        border-bottom: 2px solid transparent;
        transition: var(--transition);

        &:hover {
          border-color: var(--color-main);
        }
      }
    }
  }

  &-disclaimer {
    flex: 1 100%;
    max-width: 70%;
    margin-top: 3rem;
    background: var(--color-main);
    border-radius: 0.3rem;
    text-align: center;
    color: var(--color-bg);
    padding: 0.65rem 0;
    font-weight: 500;
    font-size: 1.1rem;

    @media screen and (max-width: 480px) {
      max-width: 100%;
      order: 5;
    }
  }

  &-links {
    flex: 1 100%;
    max-width: 30%;
    margin-top: 0rem;
    margin-left: auto;
    padding-left: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 480px) {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }

    a {
      display: block;
      text-decoration: none;
      color: var(--color-text);
      font-size: 0.842rem;
      font-weight: 500;
      padding: 2px 0;
      padding-left: 2rem;
      position: relative;
      transition: var(--transition);

      @media screen and (max-width: 480px) {
        font-size: 0.9rem;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -1px;
        left: 0;
        height: 3px;
        width: 1.5rem;
        background: var(--color-main);
        transition: var(--transition);
      }

      &:hover {
        padding-left: 2.25rem;
        color: var(--color-main);

        &::before {
          width: 1.75rem;
        }
      }
    }
  }
}
</style>
