<template>
  <div class="page__pain">
    <div class="page__pain-title">Strach i ból związany z zastrzykami</div>
    <div class="page__pain-details">
      <ul>
        <li>
          <strong>94%</strong> użytkowników insuliny ma objawy rozdrażnienia,
          niepokoju lub fobii związanej z robieniem zastrzyków i widokiem
          krwi<sup>(1)</sup>
        </li>
        <li>
          <strong>33%</strong> użytkowników insuliny boi się zastrzyków<sup
            >(2)</sup
          >
        </li>
        <li>
          <strong>75%</strong> nowo zdiagnozowanych dzieci powyżej 9 roku życia
          boi się zastrzyków<sup>(3)</sup>
        </li>
        <li>
          <strong>47%</strong> diabetyków twierdzi, że chętniej przestrzegaliby
          zalecanego procesu podawania insuliny<sup>(4)</sup>
        </li>
      </ul>
      <span class="page__pain-footnotes">
        <strong>ŹRÓDŁA:</strong>
        1. Berlin I, Bisserbe JC, Eiber R, Balssa, N, Sachon C, Bosquet F,
        Grimaldi, A: Phobic symptoms, particularly the fear of blood and injury,
        are associated with poor glycemic control in type 1 diabetic adults.
        Diabetes Care 1997;20:176–178
        <br />
        2. Peyrot M, Rubin RR, Kruger DF, Travis LB: Correlates of insulin
        injection omission. Diabetes Care 2010;33:240-245
        <br />
        3. Howe CJ, Ratcliffe SJ, Tuttle A, Dougherty S, Lipman TH: Needle
        anxiety in children with type 1 diabetes and their mothers. AM J Matern
        Child Nurs 2010;36:25-31
        <br />
        4. AADE Injection Impact Survey, 2007 . American Diabetes Association:
        Continuous subcutaneous insulin infusion (Position Statement). Diabetes
        Care 2002; 25 (supl. 1): S116.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagePain',
}
</script>

<style lang="scss">
.page__pain {
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  margin: 4rem auto;
  align-items: stretch;
  align-content: stretch;
  padding: 0 2rem;

  &-title {
    flex: 1 100%;
    font-size: 1.631rem;
    font-weight: 600;
    display: block;
    margin: 0 0 2rem;
    text-align: center;
    color: var(--color-text);

    @media screen and (max-width: 460px) {
      line-height: 1.2;
      padding: 0 1rem;
    }
  }

  &-details {
    max-width: 70%;
    margin: 0 auto 1rem;

    @media screen and (max-width: 460px) {
      max-width: 100%;
    }

    ul {
      margin: 1rem 0 2rem;
      list-style: none;
      padding: 0;

      li {
        display: block;
        padding: 0 0 1.5rem 1.5rem;
        font-size: 1.21rem;
        font-weight: 400;
        line-height: 1.4;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0.75rem;
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: var(--color-main);

          @media screen and (max-width: 460px) {
            top: 9px;
          }
        }

        sup {
          font-size: 0.7rem;
          top: -5px;
          vertical-align: baseline;
          position: relative;
        }
      }
    }
  }

  &-footnotes {
    display: block;
    font-size: 0.7rem;
    padding-top: 0.5rem;
    margin-top: 1rem;
    border-top: 2px solid var(--color-accent);
    line-height: 1.5;

    strong {
      display: block;
      padding-bottom: 1rem;
    }
  }
}
</style>
