<template>
  <div class="page__container">
    <PageIntro />
    <PageDescription />
    <PageBoxes />
    <PageTable :showTable="false" />
    <PageKeyVisual />
    <PageWarnings />
    <PageInstructions />
    <PageQA :list="questions" />
    <PageWidget />
  </div>
</template>

<script>
import PageIntro from '@/components/PageIntro'
import PageDescription from '@/components/PageDescription'
import PageBoxes from '@/components/PageBoxes'
import PageTable from '@/components/PageTable'
import PageKeyVisual from '@/components/PageKeyVisual'
import PageWarnings from '@/components/PageWarnings'
import PageInstructions from '@/components/PageInstructions'
import PageQA from '@/components/PageQA'
import PageWidget from '@/components/PageWidget'

export default {
  name: 'PacjenciView',
  components: {
    PageIntro,
    PageDescription,
    PageBoxes,
    PageTable,
    PageKeyVisual,
    PageWarnings,
    PageInstructions,
    PageQA,
    PageWidget,
  },

  data() {
    return {
      questions: [
        {
          q: 'Na ile opakowań otrzymam refundację?',
          a: `Zlecenie może być wystawione na cztery opakowania refundowanych igieł Insupen Original <strong>0,25x5mm i 0,30x8mm</strong>.`,
        },
        {
          q: 'Komu przysługuje refundacja?',
          a: `Refundacja NFZ igieł PIC Insupen Original 0,25x5mm i 0,30x8mm przysługuje pacjentom
              posiadającym aktualne ubezpieczenie zdrowotne na podstawie zlecenia wystawionego przez
              osobę uprawnioną m.in. przez lekarza specjalistę.`,
        },
        {
          q: 'Dlaczego warto wymieniać igły po każdej iniekcji?',
          a: `Wielokrotne stosowanie tej samej igły może niszczyć tkankę podskórną. Pacjenci
              narażeni są na lipohipertrofie, hiperglikemię i towarzyszący ból.`,
        },
      ],
    }
  },
}
</script>
