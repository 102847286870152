<template>
  <div class="page__static" v-if="this.$route.name === 'privacy'">
    <div class="page__static-title">
      <h1>Polityka prywatności</h1>
    </div>
    <div class="page__static-content">
      <p>
        Dokument opublikowany przez:<br />
        SIROWA POLAND sp. z o.o. z siedzibą w Warszawie<br />
        03-931 Warszawa, ul. Poselska 11, Polska<br />
        zarejestrowana w Sądzie Rejonowym dla m. st. Warszawy w Warszawie,<br />
        XIII Wydział Gospodarczy<br />
        Krajowego Rejestru Sądowego pod nr KRS 0000119845,<br />
        NIP: 9511883293, REGON: 013287052<br />
        kapitał zakładowy 4.896.000,00 zł<br />
        Tel.: 48 22 518 58 00<br />
        Fax: 48 22 518 58 09<br />
        Email:
        <a href="mailto:PIC.reklamacje@sirowa.com">PIC.reklamacje@sirowa.com</a>
      </p>
      <h3>1. Informacje ogólne</h3>
      <p>
        Dziękujemy za zainteresowanie naszą stroną internetową. Ochrona
        prywatności ma dla nas ważne znaczenie. W treści niniejszego dokumentu
        pragniemy poinformować o rodzaju gromadzonych w firmie SIROWA POLAND sp.
        z o.o. z siedzibą w Warszawie, ul. Poselska 11, 03-931 Warszawa,
        wpisanej do rejestru przedsiębiorców Krajowego Rejestru Sądowego
        prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII
        Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS
        0000119845, NIP: 9511883293, REGON: 013287052, kapitał zakładowy
        4.896.000,00 zł („SIROWA”), informacji w związku z korzystaniem ze
        strony internetowej:
        <a href="https://www.iglyrefundowane.pl">www.iglyrefundowane.pl</a>
        („Witryna”) i wyjaśnić, w jaki sposób informacje te są
        wykorzystywane.<br />
        Informujemy także, że na Witrynie umieszczone zostały linki do stron
        zewnętrznych, które nie są objęte niniejszą Polityką prywatności.
        Oznacza to, że poprzez link zamieszczony na naszej Witrynie nastąpi
        przekierowanie na zewnętrzną stronę internetową, za którą SIROWA nie
        odpowiada, w szczególności nie odpowiada za zasady gromadzenia i
        przetwarzania informacji dotyczących użytkowników korzystających z tych
        zewnętrznych stron internetowych.<br />
        Użytkownik korzystający z Witryny zgadza się na warunki określone w
        niniejszej Polityce prywatności.
      </p>
      <h3>2. Zbieranie i przetwarzanie danych osobowych</h3>
      <p>
        Tak jak ma to miejsce w przypadku większości stron internetowych w
        trakcie odwiedzania Witryny w sposób rutynowy zbierane są następujące
        dane: nazwa operatora (adres IP, anonimowo), strona internetowej, z
        której nastąpiło wejście na Witrynę, odwiedzane strony Witryny, typ
        przeglądarki oraz data i czas trwania wizyty. Informacje te są poddawane
        anonimowej ocenie i nie mogą być używane do identyfikacji osób
        odwiedzających Witrynę. Zbierane informacje są wykorzystywane wyłącznie
        w celu poprawy atrakcyjności i funkcjonalności Witryny. W tym celu
        podjęliśmy niezbędne kroki techniczne i organizacyjne dla zapewnienia
        przestrzegania przepisów w zakresie ochrony prywatności danych.<br />
        Ponadto, SIROWA może gromadzić i przetwarzać dane użytkowników w postaci
        adresu e-mail oraz ewentualnie innych danych podanych dobrowolnie przez
        użytkownika (np. imię, nazwisko) w celu złożenia reklamacji lub
        przedstawienia uwag do działania Witryny. W takim przypadku podanie
        danych osobowych ma charakter dobrowolny, lecz jest niezbędne dla
        rozpatrzenia reklamacji lub udzielenia odpowiedzi na pytanie.
        Przekazując dane osobowe użytkownik godzi się na przetwarzanie danych
        osobowych wyłącznie w celach rozpatrzenia reklamacji lub udzielenia
        odpowiedzi na pytanie. W przypadku otrzymania danych osobowych w celu
        rozpatrzenia reklamacji lub udzielenia odpowiedzi na pytanie
        przechowujemy otrzymane dane w bezpieczny sposób. Dostęp do podanych
        danych osobowych użytkownika będą miały wyłącznie osoby posiadające
        szczególne uprawnienia do dostępu do tych danych osobowych. Użytkownik
        ma prawo dostępu do swoich danych, prawo żądania ich sprostowania oraz
        prawo żądania ich usunięcia, prawo do ograniczenia przetwarzania,
        przeniesienia, wyrażenia sprzeciwu co do przetwarzania danych oraz prawo
        wycofania zgody na przetwarzanie danych, z zastrzeżeniem zasad
        wynikających z przepisów o ochronie danych osobowych. Otrzymane dane
        osobowe będę przetwarzane wyłącznie przez okres niezbędny do realizacji
        reklamacji bądź udzielenia odpowiedzi na pytanie. Otrzymanych w ww.
        celach danych osobowych nie udostępniamy żadnym osobom trzecim. W
        momencie, gdy przechowywanie danych nie będzie dłużej potrzebne, będą
        one usunięte lub zanonimizowane w bezpieczny i trwały sposób albo też
        dostęp do nich zostanie zablokowany w zakresie, w jakim wymagają tego
        właściwe przepisy o retencji danych osobowych.
      </p>
      <h3>3. Cookies</h3>
      <p>
        Kiedy odwiedzasz Witrynę, możemy wysłać cookies do Twojego komputera lub
        urządzenia, którego używasz. Używamy cookies po to, aby pamiętać
        preferencje użytkownika i zapoznać się ze sposobem korzystania przez
        Ciebie ze stron internetowych.<br />
        Cookies są wykorzystywane w celu identyfikowania użytkownika i
        poprawnego przypisywania im danych historycznych, zebranych podczas
        poprzednich wizyt w Witrynie.<br />
        Używamy zarówno czasowych (na jedną sesję), jaki i stale działających
        cookies. Możesz usunąć cookies ze swojej przeglądarki w każdym czasie
        albo zablokować umieszczanie cookies na swoim sprzęcie, może to jednak
        mieć wpływ na funkcjonowanie Witryny lub nawet ją zablokować.<br />
        SIROWA umożliwia zbieranie danych o aktywnościach użytkownika w
        Witrynie, korzystając z usług dostawców w celach reklamowych, w tym z
        plików cookies Google AdWords. Oznacza to, że będziemy wyświetlać dla
        Ciebie reklamy w Internecie dopasowane do Twoich preferencji,
        wykorzystując istniejące sieci, które ułatwiają tego typu reklamę. Jak
        zawsze szanujemy Twoją prywatność i nie zbieramy żadnych informacji
        umożliwiających Twoją identyfikację przy korzystaniu z programów Google
        lub jakichkolwiek innych podmiotów.
      </p>
      <h3>4. Google Analytics</h3>
      <p>
        Na naszej Witrynie korzystamy z Google Analytics, usługi analizy sieci
        Google Inc. (dalej zwanej „Google”). Cookies są przechowywane na
        komputerze użytkownika i umożliwiają analizę sposobu korzystania ze
        Witryny przez użytkownika.<br />
        Informacje zgromadzone w cookies dotyczące korzystania przez Państwa z
        naszej Witryny są przekazywane na serwer Google w Stanach Zjednoczonych
        i tam przechowywane. Z usługi tej korzystamy na naszej stronie
        internetowej z włączoną anonimizacją IP. Oznacza to, że Państwa adres IP
        jest na naszej stronie internetowej skracany przez Google w obrębie
        państw członkowskich Unii Europejskiej lub w innych państwach będących
        stroną Porozumienia o Europejskim Obszarze Gospodarczym i tym samym
        podlega anonimizacji. Tylko w wyjątkowych przypadkach pełny adres IP
        jest przekazywany na serwer Google w Stanach Zjednoczonych i tam
        skracany. Na nasze zlecenie Google będzie wykorzystywać powyższe
        informacje w celu analizy sposobu korzystania przez Państwa z naszej
        strony internetowej, tworzenia raportów o aktywności na stronie
        internetowej, wyświetlania reklam dostosowanych do Państwa preferencji
        oraz w celu świadczenia dla nas jako operatora Witryny innych usług
        związanych z użytkowaniem Witryny i Internetu.<br />
        Adres IP przekazany przez Państwa przeglądarkę w ramach Google Analytics
        nie jest łączony z innymi danymi Google.<br />
        Wykorzystanie plików cookies, a także narzędzi Google AdWords oraz
        Google Analytics pozwoli nam – wiedząc że są Państwo zainteresowani
        naszymi produktami prezentowanymi na Witrynie – skierować do Państwa
        reklamę dotyczącą naszych produktów prezentowanych na Witrynie, także,
        gdy odwiedzać będą Państwo inne strony internetowe.<br />
        Poprzez odpowiednią konfigurację Państwa przeglądarki mogą Państwo w
        każdym czasie uniemożliwić przechowywanie plików cookies (wyłączyć
        gromadzenie cookies oraz ich usunięcie). Niektóre funkcje naszej Witryny
        mogą nie działać, jeżeli wyłączą Państwo korzystanie z cookies. Nadal
        istnienie możliwość wyłączenia analizy przez Google Analytics poprzez
        włączenie funkcji opt-out-cookies.<br />
        Mogą Państwo zapobiec rejestrowaniu danych zgromadzonych w cookies, a
        dotyczących korzystania z Witryny (w tym Państwa adresu IP) przez
        Google, jak również przetwarzaniu tych danych przez Google, pobierając i
        instalując wtyczkę do przeglądarki, znajdującą się pod linkiem:
        <a href="https://tools.google.com/dlpage/gaoptout?hl=pl"
          >https://tools.google.com/dlpage/gaoptout?hl=pl</a
        ><br />
        Mogą Państwo również zrezygnować ze spersonalizowanych reklam.
        Instrukcja wyłączenia znajduję się pod tym linkiem
        <a
          href="https://adssettings.google.pl/anonymous?sig=ACi0TCjs8RYK5J6o6-Yb1hV9iHOqP1gNKHmF0c6O0GzFLVSut9aDTKfWruXXJIqih2WQ33FrqAZL_kjlT4BEJKJ-O9z8YHfu5g0ntN7Ye6I2UdlYJPK2wwI&amp;hl=pl"
          >https://adssettings.google.pl/anonymous?sig=ACi0TCjs8RYK5J6o6-Yb1hV9iHOqP1gNKHmF0c6O0GzFLVSut9aDTKfWruXXJIqih2WQ33FrqAZL_kjlT4BEJKJ-O9z8YHfu5g0ntN7Ye6I2UdlYJPK2wwI&amp;hl=pl</a
        >.
      </p>
      <h3>5. Informacje końcowe</h3>
      <p>
        Niniejsza Polityka prywatności może być aktualizowana po to, aby
        odzwierciedlić takie zmiany, zmiany we właściwych przepisach prawa lub
        ulepszenia w zakresie przetwarzania przez nas danych. Informacja o dacie
        wejścia w życie Polityki prywatności (także w nowej wersji) będzie
        umieszczona w treści Polityki prywatności.<br />
        W przypadku ewentualnych problemów, pytań lub sugestii dotyczących
        prezentowanych na stronach Witryny informacji lub też samej Witryny, a
        także pytań dotyczących ochrony danych osobowych prosimy o kontakt pod
        adresem poczty elektronicznej
        <a href="mailto:PIC.reklamacje@sirowa.com">PIC.reklamacje@sirowa.com</a
        >. Postaramy się wyjaśnić wszelkie wątpliwości. Informujemy także, że
        każdy użytkownik ma prawo wniesienia skargi do organu nadzorczego w
        kwestiach związanych z ochroną danych osobowych.
      </p>
    </div>
  </div>

  <div class="page__static" v-if="this.$route.name === 'regulations'">
    <div class="page__static-title">
      <h1>Regulamin</h1>
    </div>
    <div class="page__static-content">
      <p>
        Imprint: Sirowa Poland sp. z o.o.<br />
        Dokument opublikowany przez:<br />
        SIROWA POLAND sp. z o.o.<br />
        ul. Poselska 11<br />
        03-931 Warszawa<br />
        Tel.: 48 22 518 58 00<br />
        Fax: 48 22 518 58 09<br />
        Email:
        <a href="mailto:PIC.reklamacje@sirowa.com">PIC.reklamacje@sirowa.com</a
        ><br />
        SIROWA POLAND sp. z o.o. z siedzibą w Warszawie,<br />
        03-931 Warszawa, ul. Poselska 11, Polska<br />
        zarejestrowana w Sądzie Rejonowym dla m. st. Warszawy w Warszawie,<br />
        XIII Wydział Gospodarczy<br />
        Krajowego Rejestru Sądowego pod nr KRS 0000119845,<br />
        NIP: 9511883293, REGON: 013287052<br />
        kapitał zakładowy 4.896.000,00 zł
      </p>
      <h3>1. Zastrzeżenia prawne</h3>
      <p>
        Niniejszy Regulamin określa zasady korzystania z serwisu internetowego
        <a href="http://www.iglyrefundowane.pl">www.iglyrefundowane.pl</a>
        (dalej: „Witryna”). Niniejszy Regulamin może być swobodnie odtwarzany,
        kopiowany oraz drukowany.<br />
        Użytkownik zapewnia, że nie będzie korzystać z Witryny w jakichkolwiek
        celach niezgodnych z prawem lub zakazanych w ramach niniejszego
        Regulaminu i obowiązujących przepisów ustawowych i wykonawczych
        Rzeczypospolitej Polskiej.<br />
        Rozpoczęcie korzystania z Witryny będzie interpretowane jako wyrażenie
        zgody na warunki określone Regulaminem bez zastrzeżeń. W przypadku braku
        akceptacji Regulaminu użytkownik powinien zaprzestać korzystania z
        Witryny.<br />
        Witryna prowadzona jest przez SIROWA POLAND sp. z o.o. z siedzibą w
        Warszawie, ul. Poselska 11, 03-931 Warszawa, wpisaną do rejestru
        przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd
        Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy
        Krajowego Rejestru Sądowego pod numerem KRS 0000119845, NIP: 9511883293,
        REGON: 013287052, kapitał zakładowy 4.896.000,00 zł („SIROWA”).<br />
        Zebrane na stronach naszej Witryny informacje zostały opracowane w
        bardzo staranny sposób i są przez cały czas poszerzane i aktualizowane.
        Niemniej jednak nie ponosimy odpowiedzialności za dokładność i
        kompletność treści Witryny. Nie ponosimy odpowiedzialności za
        bezpośrednie, pośrednie, materialne lub niematerialne szkody, które mogą
        wyniknąć z korzystania z umieszczonych na stronach Witryny informacji.
        Nie ponosimy też odpowiedzialności z tytułu zapewnienia nieprzerwanego
        dostępu do Witryny. Korzystanie z Witryny odbywa się na osobiste ryzyko
        użytkownika z wyjątkiem sytuacji, gdy w sposób umyślny lub z rażącym
        niedbalstwem publikowane są nieprawidłowe informacje. W związku z tym,
        że nie gwarantujemy braku obecności wirusów komputerowych, od
        użytkownika oczekuje się podjęcia stosownych środków zabezpieczających w
        celu zapewnienia własnej ochrony.<br />
        Mamy prawo do wprowadzania w dowolnym czasie i zakresie zmian i poprawek
        do podawanych na stronach Witryny informacji bez konieczności
        powiadomienia przed ich wprowadzeniem ani też po ich wprowadzeniu.
      </p>
      <h3>2. Informacje biznesowe i informacje o produktach</h3>
      <p>
        Prezentowane na stronach Witryny informacje mają charakter
        informacyjno-promocyjny dotyczący produktów Pic Solution. Podanych
        informacji nie można traktować jako odpowiednika profesjonalnych porad
        medycznych lub innego rodzaju porad specjalistycznych do użycia w ich
        zastępstwie. Użytkownik nie powinien podejmować działań w oparciu o te
        informacje bez uprzedniego zasięgnięcia odpowiedniej profesjonalnej
        porady. W szczególności żadnej informacji zamieszczonej w Witrynie nie
        należy rozumieć jako sugestii, iż: (1) możliwe jest uniknięcie porady
        lekarskiej, (2) nawet osoba zdrowa przyjmując suplement diety poprawi
        swój stan zdrowia, (3) nieprzyjmowanie suplementu diety może pogorszyć
        lub polepszyć stan zdrowia danej osoby, (4) suplementy diety mogą być
        stosowane jako substytuty (zamienniki) zróżnicowanej diety.
      </p>
      <h3>3. Hiperłącza</h3>
      <p>
        Oprócz prezentowania naszych własnych informacji umieszczamy na Witrynie
        również linki do zewnętrznych stron internetowych. Treść i struktura
        zewnętrznych stron internetowych pozostają poza naszą kontrolą. Nie
        zapewniamy, że informacje prezentowane na stronach zewnętrznych Witryn
        są dokładne lub poprawne. Zamieszczone linki nie stanowią również
        jakiejkolwiek formy aprobaty, wsparcia lub potwierdzenia treści
        prezentowanych na powiązanych linkami stronach. Za informacje, opinie i
        fakty prezentowane na powiązanych linkami stronach internetowych
        odpowiedzialni są poszczególni autorzy tych stron. Nie jesteśmy również
        odpowiedzialni za bezpieczeństwo techniczne tych linków ani też stron
        docelowych. W związku z tym nie ponosimy odpowiedzialności za
        jakiekolwiek szkody zaistniałe zarówno na skutek korzystania z
        informacji na tych hiperłączach jak i korzystania z samych hiperłączy.
      </p>
      <h3>
        4. Korzystanie z Witryny, ograniczenie do osobistego i niekomercyjnego
        użytku
      </h3>
      <p>
        W celu korzystania z Witryny użytkownik musi posiadać sprawny dostęp do
        Internetu. Koszty dostępu do Internetu ponosi Użytkownik, także w
        przypadku korzystania z Internetu za pomocą telefonu komórkowego.
        Użytkownik może korzystać z Witryny bez konieczności rejestracji, a
        korzystanie z Witryny jest bezpłatne i dobrowolne.<br />
        O ile nie określono inaczej, treści zamieszczone w Witrynie mogą być
        wykorzystywane tylko do użytku osobistego o charakterze niekomercyjnym.
        Użytkownik nie może modyfikować, kopiować, rozpowszechniać, przesyłać,
        wyświetlać, wykonywać, zwielokrotniać, publikować, licencjonować,
        tworzyć prac pochodnych, przekazywać ani sprzedawać jakichkolwiek treści
        uzyskanych z Witryny, w szczególności jakichkolwiek informacji, utworów,
        zdjęć, filmów uzyskanych z Witryny.<br />
        Użytkownik może wyświetlać oraz z zastrzeżeniem wyraźnie określonych
        ograniczeń lub obostrzeń dotyczących konkretnego materiału,
        elektronicznie kopiować, pobierać i drukować fragmenty materiałów z
        różnych miejsc Witryny wyłącznie do własnego użytku o charakterze
        niekomercyjnym. Wszelkie inne sposoby wykorzystywania umieszczonych na
        stronach Witryny materiałów, w tym między innymi modyfikacja,
        zwielokrotnianie, rozpowszechnianie, ponowna publikacja, wyświetlanie
        lub przesyłanie treści Witryny bez uprzedniej pisemnej zgody firmy
        SIROWA są surowo wzbronione.
      </p>
      <h3>5. Prawa autorskie</h3>
      <p>
        SIROWA zwraca uwagę, że w Witryna zawiera treści chronione prawem, w
        szczególności utwory chronione prawem autorskim oraz dobra chronione
        prawem własności przemysłowej. Użytkownik zobowiązany jest przestrzegać
        przepisów prawa własności intelektualnej, w tym praw autorskich oraz
        praw własności przemysłowej, przysługujących SIROWA lub osobom trzecim.
        Nieuprawniony użytek może stanowić naruszenie przepisów właściwego prawa
        i niniejszego Regulaminu. Użytkownik niniejszym zgadza się na
        bezpośrednie względem niego egzekwowanie praw przez właścicieli treści.
      </p>
      <ol>
        <li>
          „Prawa autorskie do logo” i/lub „Znaki firmowe”<br />
          Logo, logotypy, nazwy marek lub znaki firmowe używane na stronach tej
          Witryny są chronione i mogą być chronione przez ich właściciela/twórcę
          lub odpowiedniego właściciela praw autorskich. SIROWA jest ich
          właścicielem lub uzyskała pozwolenie na ich użytkowanie na stronach
          Witryny. Wszystkie znaki towarowe, znaki usługowe i nazwy handlowe
          (łącznie określane jako „Znaki”) są znakami firmowymi lub
          zastrzeżonymi znakami firmowymi i stanowią własność SIROWA lub innych
          odpowiednich właścicieli, którzy udzielili SIROWA prawa i licencji do
          korzystania z nich.
        </li>
        <li>
          „Prawa autorskie do zdjęć” i/lub „Prawa autorskie do obrazów”<br />
          Zdjęcia, obrazy lub filmy użyte na stronach Witryny są chronione przez
          właściciela/twórcę lub odpowiedniego właściciela praw autorskich. W
          wymaganych przypadkach SIROWA jest ich właścicielem lub uzyskała
          zezwolenie/licencję na ich użytkowanie na stronach Witryny.
        </li>
        <li>
          „Prawa autorskie do tekstu(-ów)”<br />
          Tekst(y) użyte na stronach Witryny są chronione lub mogą być chronione
          przez ich właściciela/twórcę lub odpowiedniego właściciela praw
          autorskich. W wymaganych przypadkach SIROWA jest ich właścicielem lub
          uzyskała pozwolenie/licencję na ich użytkowanie na stronach Witryny.
        </li>
      </ol>
      <h3>6. Zgłoszenia naruszeń prawa autorskiego</h3>
      <p>
        SIROWA szanuje własność intelektualną innych osób. O to samo zwracamy
        się do naszych użytkowników i osób odwiedzających Witrynę. Osoby, które
        uważają, że ich dzieło zostało skopiowane w sposób stanowiący naruszenie
        praw autorskich, proszone są o dostarczenie SIROWA określonych poniżej
        informacji. Należy pamiętać, że zgłoszenie musi dla zachowania swojej
        skuteczności zawierać wszystkie z poniższych informacji:
      </p>
      <ol>
        <li>
          odręczny lub elektroniczny podpis osoby upoważnionej do działania w
          imieniu właściciela wyłącznych praw autorskich, które zostały rzekomo
          naruszone;
        </li>
        <li>
          opis dzieła chronionego prawami autorskimi, które zdaniem
          zgłaszającego zostały naruszone;
        </li>
        <li>
          opis miejsca, gdzie znajduje się materiał, który w opinii
          zgłaszającego stanowi naruszenie praw autorskich;
        </li>
        <li>
          adres, numer telefonu i adres poczty elektronicznej zgłaszającego oraz
          wszelkie inne informacje pozwalające SIROWA skontaktować się ze
          zgłaszającym;
        </li>
        <li>
          oświadczenie zgłaszającego o wyrażanym w dobrej wierze przekonaniu, że
          na zakwestionowane wykorzystanie nie pozwala właściciel praw
          autorskich, jego przedstawiciel lub prawo;
        </li>
        <li>
          złożone przez zgłaszającego pod groźbą odpowiedzialności karnej
          oświadczenie, że powyższe informacje, które zostały zawarte w
          zawiadomieniu, są dokładne i że zgłaszający naruszenie jest
          właścicielem praw autorskich lub osobą upoważnioną do działania w
          imieniu właściciela wyłącznego prawa, które zostało rzekomo
          naruszone.<br />
          Zawiadomienia o naruszeniu praw autorskich należy kierować: na
          następujący adres pocztowy: SIROWA POLAND sp. z o.o. ul. Poselska 11
          03-931 Warszawa lub na następujący adres mailowy:
          <a href="mailto:PIC.reklamacje@sirowa.com"
            >PIC.reklamacje@sirowa.com</a
          >
          (prosimy o wpisanie w temacie „Strona internetowa
          <a href="http://www.iglyrefundowane.pl">www.iglyrefundowane.pl</a>
          zawiadomienie o naruszeniu praw”.)
        </li>
      </ol>
      <h3>7. Procedura reklamacyjna</h3>
      <p>
        Reklamacje związane z Witryną powinny być kierowane na adres
        <a href="mailto:PIC.reklamacje@sirowa.com">PIC.reklamacje@sirowa.com</a
        >.<br />
        Wszystkie reklamacje powinny zawierać imię i nazwisko użytkownika oraz
        opis problemu.<br />
        SIROWA ustosunkuje się do zgłoszonej reklamacji najpóźniej w terminie 14
        dni od daty jej otrzymania. O swojej decyzji zapadłej w wyniku
        rozpatrzenia reklamacji SIROWA niezwłocznie zawiadomi użytkownika za
        pośrednictwem poczty elektronicznej, na adres podany w reklamacji.
      </p>
      <h3>8. Ochrona danych osobowych</h3>
      <p>
        Informacje dotyczące przetwarzania danych osobowych użytkowników Witryny
        zawarte są w Polityce prywatności.
      </p>
      <h3>9. Postanowienia ogólne</h3>
      <p>
        SIROWA zastrzega sobie prawo do czasowego zawieszenia funkcjonowania
        Witryny lub zaprzestania jej prowadzenia, także bez wcześniejszego
        poinformowania użytkowników.<br />
        SIROWA ma prawo zmiany niniejszego Regulaminu. Informacja o dacie
        wejścia w życie Regulaminu (także w nowej wersji) będzie umieszczona w
        treści Regulaminu.<br />
        W sprawach nieuregulowanych postanowieniami niniejszego Regulaminu
        zastosowanie znajdują polskie przepisy.<br />
        Jeżeli jedno lub więcej niż jedno z postanowień niniejszego Regulaminu
        okaże się obecnie lub w przyszłości nieważne, to wówczas pozostałe
        postanowienia niniejszego Regulaminu zachowają pełną ważność i
        skuteczność.<br />
        W przypadku ewentualnych problemów, pytań lub sugestii dotyczących
        prezentowanych na stronach Witryny informacji lub też samej Witryny,
        prosimy o kontakt pod adresem poczty elektronicznej
        <a href="mailto:PIC.reklamacje@sirowa.com">PIC.reklamacje@sirowa.com</a
        >.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContentView',
  watch: {
    $route(to, from) {
      if (to !== from) {
        window.scrollTo(0, 0)
      }
    },
  },
}
</script>

<style lang="scss">
.page__static {
  margin: 4rem auto;
  width: 90%;
  max-width: var(--max-width);
  font-size: 0.894rem;
  line-height: 1.7;
  padding: 0 2rem;

  h1 {
    text-align: center;
    display: block;
    margin: 2rem auto 3rem;
    font-size: 1.684rem;
    color: var(--color-main);
  }

  a {
    color: var(--color-main);
    font-weight: 600;
  }
}
</style>
