<template>
  <div class="page__qa">
    <div class="page__qa-title">Pytania i odpowiedzi</div>
    <div class="page__qa-list">
      <div
        v-for="(item, i) of list"
        :key="`qa-${i}`"
        class="page__qa-item"
        @click="
          (e) =>
            e.target.closest('.page__qa-item').classList.toggle('is--active')
        "
      >
        <div class="page__qa-question" v-html="item.q" />
        <div class="page__qa-answer" v-html="item.a" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageQA',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
.page__qa {
  margin: 4rem auto 2rem;
  max-width: var(--max-width);
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-title {
    font-size: 1.894rem;
    font-weight: 600;
    color: var(--color-main);

    @media screen and (max-width: 460px) {
      text-align: center;
      line-height: 1.2;
      font-size: 1.5rem;
    }
  }
  &-list {
    margin: 2rem auto;
    max-width: 80%;

    @media screen and (max-width: 460px) {
      max-width: unset;
      margin: 2rem -1rem;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    background: var(--color-bg);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 1.5rem 2rem;
    margin-bottom: 2rem;
    border-radius: 0.5rem;
    position: relative;
    cursor: pointer;
    transition: var(--transition);

    &::after {
      content: '';
      width: 14px;
      height: 14px;
      border-right: 4px solid var(--color-text);
      border-bottom: 4px solid var(--color-text);
      position: absolute;
      top: 1.9rem;
      right: 1.6rem;
      transform: rotate(-45deg);
      transition: var(--transition);
    }

    &.is--active {
      .page__qa-answer {
        max-height: 1000px;
        padding: 1rem 2rem 0 0;
      }
      .page__qa-question {
        color: var(--color-main);
      }
      &::after {
        border-color: var(--color-main);
        transform: rotate(45deg);
      }
    }
  }

  &-question {
    font-size: 1.105rem;
    font-weight: 600;
    display: block;
    transition: var(--transition);

    @media screen and (max-width: 460px) {
      font-size: 1rem;
      padding-right: 2rem;
    }
  }
  &-answer {
    overflow: hidden;
    max-height: 0px;
    padding: 0rem 2rem 0 0;
    font-size: 0.894rem;
    line-height: 1.7;
    transition: var(--transition);
  }
}
</style>
