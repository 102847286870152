<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 204.667 204.667">
    <path
      d="M129.033,230.467A102.333,102.333,0,1,1,231.367,128.133,102.451,102.451,0,0,1,129.033,230.467"
      transform="translate(-26.7 -25.8)"
      fill="#fff"
    />
    <path
      d="M230.654,131.932A97.677,97.677,0,1,1,132.977,34.2a97.658,97.658,0,0,1,97.677,97.732"
      transform="translate(-30.589 -29.598)"
      fill="#ed1c24"
    />
    <path
      d="M125.035,320.806a7.389,7.389,0,0,1-3.835-.986l.822-2.3a7.732,7.732,0,0,0,3.287.876,2.21,2.21,0,0,0,2.465-2.191c0-1.041-.548-1.808-1.972-2.63a4.692,4.692,0,0,1-2.739-3.89c0-2.958,2.41-4.985,5.917-4.985a7.161,7.161,0,0,1,3.232.657l-.822,2.356a5.78,5.78,0,0,0-2.52-.6c-1.479,0-2.52.822-2.52,2.027,0,1.041.767,1.644,1.863,2.356,1.917,1.205,2.9,2.575,2.9,4.109,0,3.177-2.41,5.2-6.081,5.2Z"
      transform="translate(-69.431 -151.912)"
      fill="#fff"
    />
    <path
      d="M152.653,307.265c-2.849,0-4.547,4.328-4.547,7.451,0,2.246,1.041,3.67,2.739,3.67,2.849,0,4.547-4.492,4.547-7.67-.11-.822-.274-3.451-2.739-3.451M150.3,320.906c-3.451,0-5.7-2.356-5.7-6.026,0-4.985,3.177-10.08,8.491-10.08,4.163,0,5.643,3.232,5.643,6.026,0,4.821-3.232,10.08-8.436,10.08Z"
      transform="translate(-80.012 -151.957)"
      fill="#fff"
    />
    <path
      d="M173.5,314.97l4.273-22.57h3.342l-4.328,22.57Z"
      transform="translate(-93.08 -146.35)"
      fill="#fff"
    />
    <path
      d="M192.261,321.132c-2.246,0-3.561-1.479-3.561-4a13.3,13.3,0,0,1,.329-2.794l1.7-8.984h3.342l-1.643,8.656a14.241,14.241,0,0,0-.274,2.3c0,1.37.657,2.136,1.917,2.136,1.808,0,3.561-2.191,4.163-5.15l1.479-8h3.342l-2.027,10.628c-.329,1.808-.548,3.451-.767,4.821v.055h-2.9l.274-2.52-.219-.11-.164.055a6.3,6.3,0,0,1-4.985,2.9"
      transform="translate(-99.953 -152.183)"
      fill="#fff"
    />
    <path
      d="M222.67,317.822c-2.191,0-3.4-1.1-3.4-3.013a13.66,13.66,0,0,1,.329-2.739l1.479-7.56H219l.438-2.465h2.027l.548-2.9,3.451-1.041-.767,3.944h3.4l-.438,2.465h-3.4l-1.315,7.012a10.882,10.882,0,0,0-.274,2.082,1.347,1.347,0,0,0,1.479,1.534,6.623,6.623,0,0,0,1.041-.055l-.329,2.52a7.935,7.935,0,0,1-2.191.219"
      transform="translate(-113.654 -148.928)"
      fill="#fff"
    />
    <path
      d="M240.959,297.49a1.63,1.63,0,0,1-1.643-1.808,2.035,2.035,0,0,1,2.027-2.082,1.683,1.683,0,0,1,1.753,1.808,2.059,2.059,0,0,1-2.137,2.082ZM235.7,315.513l2.958-15.5H242l-2.958,15.5Z"
      transform="translate(-121.205 -146.893)"
      fill="#fff"
    />
    <path
      d="M258.3,307.265c-2.849,0-4.547,4.328-4.547,7.451,0,2.246,1.041,3.67,2.739,3.67,2.794,0,4.547-4.492,4.547-7.67-.055-.822-.219-3.451-2.739-3.451M256,320.906c-3.451,0-5.7-2.356-5.7-6.026,0-4.985,3.177-10.08,8.491-10.08,4.164,0,5.643,3.232,5.643,6.026,0,4.821-3.232,10.08-8.436,10.08Z"
      transform="translate(-127.807 -151.957)"
      fill="#fff"
    />
    <path
      d="M288.184,320.668l1.7-9.039a10.8,10.8,0,0,0,.219-1.917c0-1.424-.657-2.137-1.863-2.137-1.589,0-3.67,1.972-4.328,5.588l-1.424,7.56H279.2l1.972-10.628c.384-1.808.6-3.451.767-4.766v-.055h2.9l-.274,2.684h.274l.109-.055a6.251,6.251,0,0,1,5.095-2.9c2.246,0,3.561,1.479,3.561,3.89a16.514,16.514,0,0,1-.274,2.629l-1.753,9.258h-3.4Z"
      transform="translate(-140.875 -152.048)"
      fill="#fff"
    />
    <path
      d="M151.3,114.279v69.683h21.2V114.717a21.915,21.915,0,0,1-21.2-.438m-23.721,18.462c0,3.013-.383,4.985-1.15,5.971-.767.931-2.082,1.424-4.054,1.424-.438,0-.931,0-1.534-.055V116.635c2.739,0,4.547.438,5.423,1.26s1.315,2.63,1.315,5.423Zm13.7-24.707a14.615,14.615,0,0,0-6.957-3.89,51.02,51.02,0,0,0-13.257-1.37H99.8v81.242h20.982V154.106h5.7a27.874,27.874,0,0,0,11.176-1.917,11.008,11.008,0,0,0,5.916-5.478q1.561-3.533,1.643-11.34v-9.861a56.333,56.333,0,0,0-.822-11.285,13.561,13.561,0,0,0-3.123-6.19m88.857,30.185v-7.724c0-7.56-.657-13.148-1.917-16.763s-3.944-6.574-7.943-8.984c-4.054-2.41-9.149-3.616-15.394-3.616a30.1,30.1,0,0,0-12.162,2.3,19.624,19.624,0,0,0-8.272,6.41,19.028,19.028,0,0,0-3.616,8.436,109.371,109.371,0,0,0-.6,13.2V155.2c0,7.943.657,13.915,1.917,17.914a16.461,16.461,0,0,0,7.943,9.313c4,2.246,9.094,3.4,15.284,3.4,5.917,0,10.956-1.315,15.01-3.944s6.683-5.7,7.889-9.258c1.205-3.506,1.808-9.477,1.808-17.859v-3.177h-.055v.164H208.981v10.025c0,4.437-.274,7.286-.712,8.491a2.984,2.984,0,0,1-3.068,1.808,3.044,3.044,0,0,1-3.177-1.808c-.438-1.205-.712-3.78-.712-7.724l.055-37.745c0-4.109.219-6.793.712-8.053s1.589-1.863,3.287-1.863c1.534,0,2.52.493,2.958,1.534s.657,3.561.657,7.67v14.024h21.036v.11ZM162.416,72.7A19.148,19.148,0,0,0,142.859,91.38c-.11,10.409,8.546,18.9,19.229,18.955a19.148,19.148,0,0,0,19.557-18.681c.11-10.354-8.491-18.845-19.229-18.955"
      transform="translate(-59.754 -47.006)"
      fill="#fff"
    />
  </svg>
</template>
