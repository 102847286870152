<template>
  <div class="page__intro" :class="style">
    <div
      class="page__intro-background"
      :style="`background-image: url('${image(background)}')`"
    />
    <div class="page__intro-hero">
      <div class="page__intro-title">{{ title }}</div>
      <div class="page__intro-claim">{{ claim }}</div>
    </div>
    <div class="page__intro-product">
      <div class="page__intro-product-packshot">
        <img
          :src="require('@/assets/pic-packshot-full.png')"
          alt="PIC Insupen Original"
        />
      </div>
      <div class="page__intro-product-icons">
        <div class="page__intro-product-icon">
          <img
            :src="require('@/assets/icon-softsharpening.png')"
            alt="SoftSharpening Technology"
          />
        </div>
        <div class="page__intro-product-icon">
          <img
            :src="require('@/assets/icon-made-in-italy.png')"
            alt="Made in Italy"
          />
        </div>
      </div>
    </div>
    <div class="page__intro-welcome">
      <h1>{{ h1 }}</h1>
      <h2>{{ h2 }}</h2>
    </div>
    <a :href="flashcards.link" class="page__intro-flashcards">
      <span v-html="flashcards.text" />
      <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M447.057 208.291c5.523 0 10-4.478 10-10s-4.477-10-10-10h-32.423c-5.523 0-10 4.478-10 10v80.001c0 5.523 4.477 10 10 10s10-4.478 10-10v-30.758h19.847c5.523 0 10-4.478 10-10s-4.477-10-10-10h-19.847v-19.242h22.423zM348.172 188.29h-22.657c-5.527 0-10.01 4.49-10 10.019V276.992c0 7.269 4.89 11.3 10.162 11.3h.038c.685-.003 16.84-.065 23.295-.179 24.028-.419 41.467-21.372 41.467-49.822 0-29.907-17.002-50.001-42.305-50.001zm.487 79.826c-2.99.052-8.268.094-13.017.124-.1-31.311-.041-20.327-.11-59.95h12.639c16.446 0 22.306 15.497 22.306 30 0 14.656-6.746 29.563-21.818 29.826zM267.753 188.29h-22.275c-5.523 0-10 4.487-10 10v80.001c0 5.523 4.477 10 10 10s10-4.478 10-10v-24.804c4.493-.023 9.441-.044 12.275-.044 18.205 0 33.017-14.613 33.017-32.576s-14.811-32.577-33.017-32.577zm0 45.152c-2.825 0-7.708.021-12.175.044-.065-13.112-.023-8.008-.07-25.195h12.245c7.056 0 13.018 5.759 13.018 12.575 0 6.817-5.961 12.576-13.018 12.576z"
        ></path>
        <path
          d="M480.626 140.509h-55.743V15c0-8.284-6.716-15-14.999-15H174.631a15.07 15.07 0 00-10.606 4.393l-95.437 95.43a15.064 15.064 0 00-4.394 10.607v147.505h-5.181c-8.284 0-14.999 6.716-14.999 15v103.973H15.017c-12.6 0-19.586 14.661-11.65 24.448l85.222 105.095c6.005 7.404 17.306 7.39 23.3 0l19.825-24.448h278.169c8.284 0 14.999-6.716 14.999-15V336.554h55.743c17.3 0 31.374-14.075 31.374-31.375V171.885c.001-17.301-14.074-31.376-31.373-31.376zM159.632 51.211V95.43H115.41l44.222-44.219zM46.492 406.909h12.522c8.284 0 14.999-6.716 14.999-15V287.936h52.451v103.973c0 8.284 6.716 15 14.999 15h12.522l-40.992 50.551-.004.005-12.751 15.724zm348.392 45.094H156.042l41.069-50.646c7.935-9.785.953-24.448-11.65-24.448h-28.998V272.936c0-8.284-6.716-15-14.999-15h-47.27V125.431h80.437c8.284 0 14.999-6.716 14.999-15V30h205.253v110.509H211.792c-17.3 0-31.375 14.075-31.375 31.376v133.294c0 17.3 14.075 31.375 31.375 31.375h183.092zm87.117-146.824c0 .758-.617 1.375-1.375 1.375H211.792a1.377 1.377 0 01-1.376-1.375V171.885c0-.759.617-1.376 1.376-1.376h268.834c.758 0 1.375.617 1.375 1.376z"
        ></path>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'PageIntro',
  props: {
    style: {
      type: String,
      default: '',
    },
    background: {
      type: String,
      default: 'insupen-hero-pacjenci.jpg',
    },
    title: {
      type: String,
      default: 'Każdego dnia delikatne zastrzyki',
    },
    claim: {
      type: String,
      default: 'Igły PIC Insupen Original objęte refundacją NFZ',
    },
    h1: {
      type: String,
      default: 'Delikatne zastrzyki zawsze przy tobie',
    },
    h2: {
      type: String,
      default:
        'Zapytaj swojego lekarza lub farmaceutę o refundację igieł PIC Insupen Original',
    },
  },

  data() {
    return {
      flashcards: {
        link: '/fiszki-powiklania-skorne.pdf',
        text: 'Fiszki edukacyjne dla pacjenta<br/>Część I: Powikłania skórne (do pobrania)',
      },
    }
  },

  methods: {
    image(file) {
      return require(`@/assets/${file}`)
    },
  },
}
</script>

<style lang="scss">
.page__intro {
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  overflow-x: hidden;

  &.no--shadow {
    .page__intro-title {
      text-shadow: none;
      color: var(--color-main);
    }

    .page__intro-background {
      @media screen and (max-width: 460px) {
        background-size: 160%;
        background-position: 80% -75%;
      }
    }
  }

  &-flashcards {
    width: calc(100% - 4rem);
    max-width: calc(var(--max-width) - 4rem);
    padding: 1.5rem;
    margin: 3rem auto 2rem;
    background: var(--color-accent);
    border-radius: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--color-main);
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;
    transition: var(--transition);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border: 3px solid transparent;
    gap: 2rem;
    position: relative;
    text-transform: uppercase;

    &:hover {
      background-color: var(--color-main);
      color: #fff;
      border-color: var(--color-accent);
    }

    @media screen and (max-width: 460px) {
      width: calc(100% - 2rem);
      font-size: 1.5rem;
      font-weight: 600;
      line-height: 1.3;
      padding-bottom: 7rem;
    }

    svg {
      fill: currentColor;
      width: 4rem;
      height: 4rem;
      position: absolute;
      right: 3rem;

      @media screen and (max-width: 460px) {
        right: calc(50% - 2rem);
        bottom: 1.5rem;
      }
    }
  }

  &-background {
    width: 100%;
    height: 38rem;
    position: absolute;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 50% 40%;
    z-index: 1;

    @media screen and (max-width: 980px) {
      height: 28rem;
      background-size: 150%;
      background-position: 50% 0;
    }
  }
  &-hero {
    position: relative;
    z-index: 2;
    max-width: var(--max-width);
    // padding: 0 2rem;
    margin: 0 auto;
    height: 38rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    @media screen and (max-width: 1200px) {
      padding-right: 2rem;
    }
    @media screen and (max-width: 980px) {
      height: 28rem;
    }
    @media screen and (max-width: 460px) {
      padding: 0 1rem;
    }
  }

  &-title {
    margin: 0;
    padding: 0;
    font-size: 2.6rem;
    max-width: 500px;
    text-align: left;
    font-weight: 600;
    color: var(--color-bg);
    line-height: 1.2;
    text-shadow: 3px 2px 8px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 1200px) {
      max-width: 400px;
    }
    @media screen and (max-width: 980px) {
      max-width: 360px;
      font-size: 2rem;
    }
    @media screen and (max-width: 460px) {
      max-width: 230px;
      font-size: 1.5rem;
    }
  }

  &-claim {
    color: var(--color-bg);
    font-size: 1.7rem;
    max-width: 500px;
    text-align: left;
    background: var(--color-main);
    padding: 1.4rem 0 1.4rem 2.5rem;
    line-height: 1.2;
    margin-top: 3rem;
    border-radius: 1rem 0 0 1rem;
    position: relative;

    @media screen and (max-width: 1200px) {
      max-width: 400px;
    }
    @media screen and (max-width: 980px) {
      font-size: 1.5rem;
      margin-top: 2rem;
    }
    @media screen and (max-width: 460px) {
      max-width: 230px;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.3;
      margin-right: 0rem;
      margin-top: 1rem;
      padding: 1rem 0 1rem 1.25rem;
    }

    &::before {
      content: '';
      width: 100%;
      left: 100%;
      top: 0;
      height: 100%;
      position: absolute;
      background: inherit;
    }
  }

  &-product {
    display: flex;
    position: relative;
    z-index: 19;
    align-items: stretch;
    max-width: var(--max-width);
    margin: 0 auto;
    margin-top: -6rem;
    padding: 0 2rem;

    @media screen and (max-width: 460px) {
      flex-wrap: wrap;
    }

    &::before {
      content: '';
      width: 100%;
      height: 4px;
      background: var(--color-accent);
      top: calc(50% - 3rem);
      left: 20%;
      z-index: 1;
      position: absolute;

      @media screen and (max-width: 460px) {
        top: calc(50% - 7rem);
      }
    }

    &-packshot {
      margin: 0 0 0 -9rem;
      position: relative;
      z-index: 5;
      max-height: 20rem;

      @media screen and (max-width: 460px) {
        margin-left: -5rem;
      }

      img {
        max-height: 100%;
      }
    }

    &-icons {
      position: relative;
      z-index: 6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      flex: 1;
      padding: 6rem 0 0;

      @media screen and (max-width: 460px) {
        padding-top: 1rem;
        flex-wrap: wrap;
        align-items: center;
      }
    }

    &-icon {
      img {
        max-width: 100%;
      }

      &:first-child {
        @media screen and (max-width: 460px) {
          margin-bottom: 1rem;
        }
      }
      &:last-child {
        img {
          @media screen and (max-width: 460px) {
            width: 7rem;
          }
        }
      }
    }
  }

  &-welcome {
    width: calc(100% - 4rem);
    max-width: calc(var(--max-width) - 4rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-grey);
    color: var(--color-main);
    border-radius: 0.5rem;
    margin: 3rem auto 2rem;
    padding: 1.5rem 0;

    @media screen and (max-width: 460px) {
      margin: 2rem auto;
      width: 90%;
      padding: 1rem;
    }

    h1 {
      margin: 0;
      padding: 0;
      font-size: 2rem;
      font-weight: 600;
      text-transform: uppercase;

      @media screen and (max-width: 460px) {
        font-size: 1.5rem;
        line-height: 1.2;
        text-align: center;
      }
    }

    h2 {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 1.4rem;

      @media screen and (max-width: 460px) {
        font-size: 1rem;
        text-align: center;
        padding-top: 1rem;
      }
    }
  }
}

.page--lekarze {
  .page__intro {
    &-background {
      background: rgb(245, 245, 245) !important;
      background: linear-gradient(
        130deg,
        rgba(245, 245, 245, 1) 0%,
        rgba(230, 230, 230, 1) 100%
      ) !important;
      height: 43rem;
    }
    &-title {
      text-shadow: none;
      color: var(--color-main);
    }

    &-hero {
      @media screen and (max-width: 1200px) {
        padding-right: 2rem;
        height: 15rem;
      }
    }

    &-welcome {
      position: relative;
      z-index: 19;
      margin-top: 1rem;

      @media screen and (max-width: 460px) {
        margin-top: 3rem;
      }
    }

    &-product {
      position: absolute;
      top: 0;
      margin-top: 6rem;
      padding: 0 3rem;
      flex-direction: column;
      width: 100%;
      max-width: 100%;

      @media screen and (max-width: 460px) {
        position: relative;
        margin-top: -1rem;
        padding: 0 1rem;
      }

      &::before {
        top: calc(100% - 1rem);
        left: 4rem;

        @media screen and (max-width: 460px) {
          top: calc(100% - 3.5rem);
          height: 3px;
        }
      }

      &-packshot {
        margin: 0 0 0 -12rem;
        max-height: 25rem;

        @media screen and (max-width: 1200px) {
          margin-left: -11rem;
        }
        @media screen and (max-width: 460px) {
          margin-left: -5rem;
        }

        img {
          @media screen and (max-width: 1200px) {
            max-height: 21rem;
            margin-bottom: 3rem;
          }
          @media screen and (max-width: 460px) {
            margin-bottom: 1rem;
          }
        }
      }

      &-icons {
        padding: 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 460px) {
          flex-direction: column;
        }

        div:last-child {
          margin-top: -4rem;

          @media screen and (max-width: 460px) {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
