<template>
  <div class="page__description short--desc">
    <div class="page__description-column">
      <p class="page__description-column-text">
        <strong>Refundowane igły do penów insulinowych Insupen Original</strong>
        to wyrób medyczny włoskiej produkcji stosowany do aplikacji insuliny.
      </p>

      <p class="page__description-column-text">
        Igły posiadają trójpłaszczyznowe ostrze <strong>SoftSharpening</strong>:
        sterylne, cienkościenne, z silikonową powłoką.
      </p>
    </div>

    <div class="page__description-column">
      <p class="page__description-column-text">
        <strong
          >Refundacja NFZ igieł PIC Insupen Original 0,25x5mm (31G) i 0,30x8mm
          (30G)</strong
        >
        przysługuje pacjentom posiadającym aktualne ubezpieczenie zdrowotne na
        podstawie zlecenia wystawionego przez osobę uprawnioną m.in. przez
        lekarza specjalistę.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageShortDesc',
}
</script>

<style lang="scss">
.short--desc {
  margin-bottom: 4rem;
  padding-top: 1rem;

  @media screen and (max-width: 460px) {
    flex-direction: column;
  }

  p {
    margin: 1rem 0;
    font-size: 1.105rem;
    line-height: 1.7;
    color: var(--color-main);
    padding-right: 2rem;

    @media screen and (max-width: 460px) {
      padding: 0;
      width: 100%;
      margin: 1rem 0 0;
    }

    strong {
      font-weight: 600;
    }
  }
}
</style>
