import { createRouter, createWebHistory } from 'vue-router'
import PacjenciView from '../views/PacjenciView'
import LekarzeView from '../views/LekarzeView'
import FarmaceuciView from '../views/FarmaceuciView'
import ContentView from '../views/ContentView'

const routes = [
  {
    path: '/',
    redirect: '/pacjenci',
    name: 'home',
    component: PacjenciView,
  },
  {
    path: '/pacjenci',
    name: 'pacjenci',
    component: PacjenciView,
  },
  {
    path: '/lekarze',
    name: 'lekarze',
    component: LekarzeView,
  },
  {
    path: '/farmaceuci',
    name: 'farmaceuci',
    component: FarmaceuciView,
  },
  {
    path: '/polityka-prywatnosci',
    name: 'privacy',
    component: ContentView,
  },
  {
    path: '/regulamin',
    name: 'regulations',
    component: ContentView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
