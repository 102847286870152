<template>
  <div class="page__therapy">
    <div class="page__therapy-container">
      <div class="page__therapy-title">
        Co należy wiedzieć o insulinoterapii?
      </div>
      <div class="page__therapy-subtitle">
        Insulina jest hormonem niezbędnym do prawidłowego metabolizmu
        węglowodanów, białek i tłuszczów. Chorzy na cukrzycę typu 1 wytwarzają
        zbyt małe ilości tego hormonu, dlatego ich życie uzależnione jest od
        insuliny dostarczanej z zewnątrz.
      </div>
      <div class="page__therapy-details">
        <p>
          <strong>
            Każdego dnia osoby cierpiące na cukrzycę Typu 1 muszą powtórzyć
            poniższe czynności 4 razy albo więcej:
          </strong>
        </p>
        <ul>
          <li>Nakłuć palec aby uzyskać kroplę krewi do testu</li>
          <li>Dokonać analizy krwi za pomocą glukometru</li>
          <li>
            Wykonać zastrzyk z insuliny za pomocą strzykawki, pena lub pompy
            insulinowej
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTherpay',
}
</script>

<style lang="scss">
.page__therapy {
  background: var(--color-main);
  margin: 4rem auto;
  padding: 4rem 0;
  color: var(--color-bg);

  &-container {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: var(--max-width);
    margin: auto;
    align-items: stretch;
    align-content: stretch;

    @media screen and (max-width: 460px) {
      width: 90%;
    }
  }

  &-title {
    flex: 1 100%;
    font-size: 1.631rem;
    font-weight: 600;
    display: block;
    margin: 0 0 2rem;
    text-align: center;

    @media screen and (max-width: 460px) {
      line-height: 1.3;
    }
  }

  &-subtitle {
    font-size: 1rem;
    text-align: center;
    line-height: 1.6;

    @media screen and (max-width: 460px) {
      line-height: 1.5;
      padding: 0 1rem;
      text-align: left;
    }
  }

  &-details {
    max-width: 65%;
    margin: 2rem auto 0rem;

    @media screen and (max-width: 460px) {
      max-width: 100%;
      padding: 0 1rem;
    }

    strong {
      font-weight: 600;
    }

    ul {
      margin: 2rem 0 0rem;
      list-style: none;
      padding: 0;

      li {
        display: block;
        padding: 0 0 1.5rem 1.5rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.4;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0.5rem;
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: var(--color-bg);
        }

        sup {
          font-size: 0.7rem;
          top: -5px;
          vertical-align: baseline;
          position: relative;
        }
      }
    }
  }

  &-footnotes {
    display: block;
    font-size: 0.7rem;
    padding-top: 0.5rem;
    margin-top: 1rem;
    border-top: 2px solid var(--color-accent);
    line-height: 1.5;

    strong {
      display: block;
      padding-bottom: 1rem;
    }
  }
}
</style>
