<template>
  <div class="page__container">
    <PageStatement v-if="!statement" @accepted="statement = true" />
    <PageIntro
      h1="Każdego dnia po Twojej stronie"
      h2="Igły PIC Insupen Original 0,25x5 mm (31G) i 0,30x8 mm (30G) objęte refundacją NFZ"
      :style="`page--lekarze`"
    />
    <PageShortDesc />
    <PageTable :subtitle="false" />
    <PagePain />
    <PageKeyVisual />
    <PageWarnings
      title="Pacjent powinien zmieniać igłę do pena insulinowego po każdym zastrzyku!"
      :subtitle="false"
      useTitle="Nakłucia igłą mogą być BOLESNE, ponieważ:"
    />
    <PageQA :list="questions" />
  </div>
</template>

<script>
import PageIntro from '@/components/PageIntro'
import PageShortDesc from '@/components/PageShortDesc'
import PageTable from '@/components/PageTable'
import PageKeyVisual from '@/components/PageKeyVisual'
import PageWarnings from '@/components/PageWarnings'
import PageQA from '@/components/PageQA'
import PagePain from '@/components/PagePain'
import PageStatement from '@/components/PageStatement'

export default {
  name: 'FarmaceuciView',
  components: {
    PageIntro,
    PageShortDesc,
    PageTable,
    PageKeyVisual,
    PageWarnings,
    PageQA,
    PagePain,
    PageStatement,
  },

  data() {
    return {
      statement: false,
      questions: [
        {
          q: 'Kto może wystawić zlecenie na igły do penów insulinowych?',
          a: `Refundacja przysługuje pacjentom na podstawie zlecenia wystawionego przez osobę uprawnioną m.in. lekarza specjalistę, w tym lekarza rodzinnego.`,
        },
        {
          q: 'Na ile opakowań może być wystawione zlecenie?',
          a: `Zlecenie może być wystawione na cztery opakowania refundowanych igieł Insupen Original 0,25x5mm i 0,30x8mm.`,
        },
        {
          q: 'Kto ma prawo do takiej refundacji?',
          a: `Refundacja NFZ igieł PIC Insupen Original 0,25x5mm i 0,30x8mm przysługuje pacjentom posiadającym aktualne ubezpieczenie zdrowotne na podstawie zlecenia wystawionego przez osobę uprawnioną m.in. przez lekarza specjalistę.`,
        },
      ],
    }
  },
}
</script>
