<template>
  <div class="page__container">
    <PageStatement v-if="!statement" @accepted="statement = true" />
    <PageIntro
      h1="Każdego dnia delikatne zastrzyki"
      h2="Igły PIC Insupen Original 0,25x5 mm (31G) i 0,30x8 mm (30G) objęte refundacją NFZ"
      :style="`page--lekarze`"
    />
    <PageLekarzeDesc />
    <PageTherapy />
    <PagePain />
    <PageKeyVisual />
    <PageWarnings
      title=""
      :subtitle="false"
      useTitle="Używanie pena insulinowego może być bardziej bolesne, ponieważ:"
    />
    <PageQA :list="questions" />
  </div>
</template>

<script>
import PageIntro from '@/components/PageIntro'
import PageLekarzeDesc from '@/components/PageLekarzeDesc'
import PageKeyVisual from '@/components/PageKeyVisual'
import PageWarnings from '@/components/PageWarnings'
import PageQA from '@/components/PageQA'
import PagePain from '@/components/PagePain'
import PageTherapy from '@/components/PageTherapy'
import PageStatement from '@/components/PageStatement'

export default {
  name: 'LekarzeView',
  components: {
    PageIntro,
    PageLekarzeDesc,
    PageKeyVisual,
    PageWarnings,
    PageQA,
    PagePain,
    PageTherapy,
    PageStatement,
  },

  data() {
    return {
      statement: false,
      questions: [
        {
          q: 'Na ile opakowań mogę wypisać refundację?',
          a: `Zlecenie może być wystawione na cztery opakowania refundowanych igieł Insupen Original 0,25x5mm (31G) i 0,30x8mm (30G)`,
        },
        {
          q: 'Ile igieł jest w opakowaniu?',
          a: `W opakowaniu igieł refundowanych Insupen Original 0,25x5mm (31G) i 0,30x8mm (30G) znajduje się 100 sztuk.`,
        },
        {
          q: 'Które igły są refundowane?',
          a: `Refundacją objęte są igły Insupen Original 0,25x5mm (31G) i 0,30x8mm (30G).`,
        },
      ],
    }
  },
}
</script>
