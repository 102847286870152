<template>
  <div class="page__widget">
    <div class="page__widget-container">
      <div class="page__widget-claim">
        Gdzie znajdziesz refundowane<br />
        igły do insuliny?
      </div>
      <div class="page__widget-buttons">
        <a :href="link" target="_blank" class="page__widget-logo">
          <KtoMaLek />
        </a>
        <a :href="link" target="_blank" class="page__widget-button">
          <span>Sprawdź</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import KtoMaLek from '@/assets/LogoKtoMaLek'

export default {
  name: 'PageWidget',

  components: {
    KtoMaLek,
  },

  data() {
    return {
      link: 'https://ktoMalek.pl/leon-www/rezerwacjeLekow/KLEK/gdzieKupieLeki?&ids=9098517&typ=A',
    }
  },
}
</script>

<style lang="scss">
.page__widget {
  background: var(--color-main);
  margin: 4rem auto 0;
  padding: 4rem 0;
  color: var(--color-bg);

  &-container {
    display: flex;
    flex-direction: row;
    width: 80%;
    max-width: var(--max-width);
    margin: auto;
    align-items: center;
    align-content: center;

    @media screen and (max-width: 460px) {
      width: 100%;
      flex-direction: column;
      padding: 0 3rem;
    }
  }

  &-claim {
    font-size: 2.166rem;
    font-weight: 600;
    flex: 1;
    max-width: 70%;
    line-height: 1.2;

    @media screen and (max-width: 460px) {
      max-width: 100%;
      font-size: 2rem;
      text-align: center;
    }
  }

  &-buttons {
    flex: 1;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media screen and (max-width: 460px) {
      max-width: 100%;
      padding-top: 3rem;
      align-items: center;
    }
  }

  &-logo {
    flex: 1;

    svg {
      width: 14rem;

      @media screen and (max-width: 460px) {
        max-width: 100%;
      }
    }
  }

  &-button {
    margin-top: 2rem;
    background: var(--color-bg);
    padding: 1rem;
    text-align: center;
    border-radius: 0.6rem;
    max-width: 14rem;
    transition: var(--transition);
    position: relative;
    flex: 1;

    @media screen and (max-width: 460px) {
      min-width: 13rem;
    }

    span {
      font-size: 1.25rem;
      color: var(--color-main);
      font-weight: 600;
      padding-right: 1.5rem;
      transition: var(--transition);
    }

    &::after {
      content: '';
      width: 14px;
      height: 14px;
      border-right: 4px solid var(--color-main);
      border-bottom: 4px solid var(--color-main);
      position: absolute;
      top: calc(50% - 7px);
      right: 2rem;
      transform: rotate(-45deg);
      transition: var(--transition);
    }

    &:hover {
      span {
        padding-right: 1.75rem;
      }

      &::after {
        right: 2.5rem;
      }
    }
  }
}
</style>
