<template>
  <div class="statement__overlay">
    <div class="statement__overlay-container">
      <strong>Oświadczenie</strong>
      <p>
        Dostęp do treści zamieszczonych na stronie dla profesjonalistów możliwy
        jest dla osób uprawnionych zajmujących się ochroną zdrowia lub
        uprawnionymi do wystawiania recept lub zajmujących się obrotem
        produktami leczniczymi.
      </p>
      <div class="statement__overlay-buttons">
        <button @click="accept()" class="statement__overlay-button is--ok">
          <span>Oświadczam, że jestem lekarzem/dietetykiem/farmaceutą</span>
        </button>
        <router-link to="/pacjenci" class="statement__overlay-button is--error">
          <span>Nie jestem lekarzem/dietetykiem/farmaceutą</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageStatement',
  methods: {
    accept() {
      console.log('ok')
      this.$emit('accepted')
    },
  },
}
</script>

<style lang="scss">
.statement__overlay {
  position: fixed;
  z-index: 999999;
  background: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-container {
    max-width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 460px) {
      max-width: 80%;
    }

    strong {
      font-size: 2rem;
      font-weight: 700;
      text-transform: uppercase;
    }
    p {
      margin: 2rem 0;
      font-size: 1.105rem;
      line-height: 1.7;
      text-align: center;

      @media screen and (max-width: 460px) {
        line-height: 1.5;
      }
    }
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-button {
    margin: 1rem 0 0;
    border: 0;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.75rem 1.25rem;
    font-family: var(--font-family);
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05);
    color: var(--color-bg);
    cursor: pointer;
    transition: var(--transition);
    text-align: center;

    &.is--ok {
      background: var(--color-main);
    }
    &.is--error {
      background: var(--color-logo);
    }

    &:hover {
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
    }
  }
}
</style>
