<template>
  <div class="page__kv">
    <div class="page__kv-container">
      <div class="page__kv-packshot">
        <img
          :src="require('@/assets/pic-packshot-full.png')"
          alt="PIC Insupen Original"
        />
      </div>
      <div class="page__kv-branding">
        <LogoInsupen class="page__kv-insupen" />
        <LogoPic class="page__kv-logo" />
        <div class="page__kv-branding-claim">
          <strong>
            Każdego dnia<br />
            delikatne zastrzyki
          </strong>
        </div>
      </div>
      <div class="page__kv-icons">
        <div class="page__kv-icon">
          <img
            :src="require('@/assets/icon-softsharpening.png')"
            alt="SoftSharpening Technology"
          />
        </div>
        <div class="page__kv-icon">
          <img
            :src="require('@/assets/icon-made-in-italy.png')"
            alt="Made in Italy"
          />
        </div>
      </div>

      <div class="page__kv-details">
        <div class="page__kv-details-item">
          <strong>Większa delikatność</strong>
          <p>
            Specjalna potrójnie ostrzona igła do delikatnych, komfortowe
            iniekcje bez zastanowienia.
          </p>
        </div>

        <div class="page__kv-details-item">
          <strong>Zmniejszone tarcie</strong>
          <p>Ostrzenie trzyczęściowe redukuje punkty tarcia.</p>
        </div>

        <div class="page__kv-details-item">
          <strong>Mniejszy dyskomfort</strong>
          <p>Cienki profil zmniejsza uczucie dyskomfortu.</p>
        </div>

        <div class="page__kv-details-item">
          <strong>Mniejsza inwazyjność</strong>
          <p>
            Ostra końcówka i specyficzny kąt ostrzenia ułatwiają wstrzykiwanie.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoPic from '@/components/LogoPic'
import LogoInsupen from '@/components/LogoInsupen'

export default {
  name: 'PageKeyVisual',
  components: {
    LogoPic,
    LogoInsupen,
  },
}
</script>

<style lang="scss">
.page__kv {
  padding: 5rem 0 2rem;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    130deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(242, 242, 242, 1) 100%
  );

  @media screen and (max-width: 460px) {
    padding: 3rem 0;
  }

  &-container {
    width: 100%;
    max-width: var(--max-width);
    padding: 0 2rem;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 460px) {
      flex-direction: column;
    }
  }

  &-packshot {
    flex: 1;
    max-width: 50%;

    @media screen and (max-width: 460px) {
      max-width: 100%;
      order: 2;
    }

    img {
      max-width: 130%;
      margin-left: -25%;
    }
  }

  &-branding {
    flex: 1;
    max-width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;
    flex-wrap: wrap;

    @media screen and (max-width: 460px) {
      order: 1;
      max-width: 100%;
      justify-content: space-between;
    }

    &-claim {
      flex: 1 100%;
      display: flex;
      justify-content: flex-end;

      @media screen and (max-width: 460px) {
        justify-content: flex-start;
      }

      strong {
        font-size: 2.6rem;
        color: var(--color-main);
        font-weight: 600;
        line-height: 1.2;
        margin: 1rem 0 2rem;

        @media screen and (max-width: 460px) {
          font-size: 2rem;
          margin: 2rem 0 2rem;
        }
      }
    }
  }
  &-insupen {
    max-width: 10rem;
    flex: 1;
    margin-right: 2rem;

    @media screen and (max-width: 460px) {
      max-width: 8rem;
    }
  }
  &-logo {
    max-width: 7rem;
    border: 3px solid #fff;
    border-radius: 100%;
  }

  &-icons {
    flex: 1 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 460px) {
      order: 3;
      flex-direction: column;
    }
  }

  &-icon:first-child {
    width: 60%;
    position: relative;

    @media screen and (max-width: 460px) {
      width: 100%;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    &::after {
      content: '';
      position: absolute;
      top: 80%;
      left: 50px;
      height: 4px;
      width: calc(100% - 50px);
      background: var(--color-accent);
      z-index: 1;
    }

    img {
      position: relative;
      z-index: 2;
    }
  }

  &-details {
    flex: 1 100%;
    width: 100%;
    padding: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;

    @media screen and (max-width: 460px) {
      order: 4;
    }

    &-item {
      flex: 1 100%;
      max-width: 45%;
      padding: 3rem 0 1rem;

      @media screen and (max-width: 460px) {
        max-width: 100%;
      }

      strong {
        font-size: 1.631rem;
        color: var(--color-main);
      }

      p {
        margin: 0;
        padding: 1rem 0 0;
        font-size: 1.157rem;
        color: var(--color-main);
        line-height: 1.5;
      }
    }
  }
}
</style>
