<template>
  <div class="page__warnings" :class="{ 'is--short': !title }">
    <div class="page__warnings-title" v-if="title !== ''">
      {{ title }}
    </div>
    <div class="page__warnings-intro" v-if="subtitle">
      Jeśli jednak używasz tej samej igły do więcej niż jednego zastrzyku, ma to
      szereg konsekwencji dla insulinoterapii.
      <br />
      Co się stanie, jeśli nie zmienisz igły? Terapia może być nieefektywna z
      powodu:
    </div>

    <div class="page__warnings-column" v-if="subtitle">
      <div class="page__warnings-column-subtitle">Krystalizacji</div>
      <div class="page__warnings-column-text">
        Jeśli po użyciu pena pozostawisz w nim igłę, w środku mogą pozostać
        ślady insuliny. Ponieważ insulina ma tendencję do krystalizowania się
        pod wpływem powietrza, jest prawdopodobne, że igła, która była wcześniej
        używana, będzie zawierała skrystalizowaną insulinę, która może
        całkowicie zatkać igłę i uniemożliwić wykonanie wstrzyknięcia.<sup
          >(1-2)</sup
        >
      </div>
    </div>

    <div class="page__warnings-column" v-if="subtitle">
      <div class="page__warnings-column-subtitle">Lipodystrofii</div>
      <div class="page__warnings-column-text">
        Ponownie użyta igła ma zagiętą i/lub zdeformowaną końcówkę; może
        powodować zarówno mikrourazy (otarcia i krwawienia skóry), jak i
        lipodystrofię, która jest zmianą w normalnym rozkładzie tkanki
        tłuszczowej i objawia się jako ograniczony, wgłębiony obszar lub miękki,
        powierzchowny guzek. miękkiego, powierzchownego guzka. Czym ryzykujesz?
        Wstrzykiwanie w obszar już dotknięty lipodystrofią nie gwarantuje, że
        lek zostanie wchłonięty co sprawia, że terapia jest nieskuteczna.<sup
          >(1-2-3-4-5)</sup
        >
      </div>
    </div>

    <div class="page__warnings-column-head column--wide">
      {{ useTitle }}
    </div>
    <div class="page__warnings-column-head column--narrow">
      Zagrożenie infekcją, ponieważ:
    </div>

    <div class="page__warnings-column column--narrow">
      <div class="page__warnings-column-subtitle">
        Końcówka igły jest uszkodzona.
      </div>
      <div class="page__warnings-column-text">
        Przy ponownym użyciu, opór skóry lub przypadkowy kontakt z ochronną
        nasadką może uszkodzić końcówkę igły. Końcówka igły staje się coraz
        bardziej "hakowata" przy każdym użyciu, uszkadzając coraz większe
        fragmenty tkanki z każdym zastrzykiem zarówno podczas wprowadzania, jak
        i wyprowadzania ze skóry. To sprawia, że terapia jest bolesna.<sup
          >(1-4-6)</sup
        >
      </div>
    </div>

    <div class="page__warnings-column column--narrow">
      <div class="page__warnings-column-subtitle">
        Igła nie jest już nasmarowana.
      </div>
      <div class="page__warnings-column-text">
        Poza tym, że ból jest spowodowany tępą końcówką igły, zależy on również
        od smarowania: ilość środka smarującego na igle zmniejsza się po
        pierwszym zastrzyku, przez co kolejne zastrzyki są mniej gładkie i
        bardziej uciążliwe.<sup>(1)</sup>
      </div>
    </div>

    <div class="page__warnings-column column--narrow">
      <div class="page__warnings-column-subtitle">
        Igła nie jest już sterylna.
      </div>
      <div class="page__warnings-column-text">
        Każda igła do pena insulinowego jest jednorazowym, sterylnym wyrobem
        medycznym; po pierwszym użyciu jednak może zostać zanieczyszczona brudem
        lub zarazkami, co czyni ją nośnikiem bakterii.<sup>(1-3-4)</sup>
      </div>
    </div>

    <div class="page__warnings-footnotes">
      <strong>ŹRÓDŁA:</strong>
      <ul>
        <li>
          1 - The Importance of Good Insulin Injection Practices in Diabetes
          Management (Znaczenie dobrych praktyk wstrzykiwania insuliny w
          leczeniu cukrzycy) - Richard Dolinar, MD, 2009.
        </li>
        <li>
          2 - Hanas R. (1999), Insulin-Dependent Diabetes in Children,
          Adolescents and Adults. Uddevalla, Szwecja.
        </li>
        <li>
          3 - I.V. Misnikova, A.V. Dreval, V.A Gubkina, E.V. Rusanova ; The
          Risks of Repeated Use of Insulin Pen Needles in The Risks of Repeated
          Use of Insulin Pen Needles in Patients with Diabetes Mellitus; Journal
          of Diabetology, 2011.
        </li>
        <li>
          4 - Podawanie insuliny; DIABETES CARE, VOLUME 27, supplement 1, 2004.
        </li>
        <li>
          5 - King L (2003) Technika podskórnego wstrzykiwania insuliny. Nursing
          Standard. 17, 34, 45-52.
        </li>
        <li>
          6 - Oświadczenie OSDI - 2011/2012 - Zalecenia dotyczące pomocy
          diabetologicznej.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageWarnings',

  props: {
    title: {
      type: String,
      default: 'Zaleca się zmianę igły do penów po każdym zastrzyku!',
    },
    subtitle: {
      type: Boolean,
      default: true,
    },
    useTitle: {
      type: String,
      default: 'Używanie pena insulinowego może być BOLESNE, ponieważ:',
    },
  },
}
</script>

<style lang="scss">
.page__warnings {
  padding: 4rem 2rem;
  max-width: var(--max-width);
  margin: 2rem auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &.is--short {
    padding-top: 1rem;
  }

  &-title {
    flex: 1 100%;
    font-size: 1.631rem;
    font-weight: 600;
    display: block;
    margin: 0 0 2rem;
    text-align: center;
    color: var(--color-text);

    @media screen and (max-width: 460px) {
      line-height: 1.2;
    }
  }

  &-intro {
    flex: 1 100%;
    font-weight: 400;
    font-size: 1.157rem;
    line-height: 1.6;
    display: block;
    margin: 0 0 4rem;
    text-align: center;
    color: var(--color-text);
  }

  &-column {
    flex: 1 auto;
    max-width: 46%;

    @media screen and (max-width: 460px) {
      max-width: 100%;
    }

    &-subtitle {
      display: block;
      font-weight: 600;
      font-size: 1.157rem;
      padding: 0 0 1rem;
      color: var(--color-main);
    }

    &-text {
      line-height: 1.6;
      font-size: 0.894rem;
      margin-bottom: 1rem;
    }

    &-head {
      margin: 3rem 0 0;
      display: block;
      font-weight: 700;
      font-size: 1.157rem;
      padding: 0 0 1rem;
      color: var(--color-text);

      &.column--wide {
        flex: 1 0 100%;
        max-width: 70%;

        @media screen and (max-width: 460px) {
          max-width: 100%;
        }
      }
      &.column--narrow {
        flex: 1 0 100%;
        max-width: 30%;

        @media screen and (max-width: 460px) {
          max-width: 100%;
          order: 3;
        }
      }
    }

    &.column--narrow {
      flex: 1 0 100%;
      max-width: 30%;

      @media screen and (max-width: 460px) {
        max-width: 100%;
      }

      .page__warnings-column-subtitle {
        font-size: 1rem;
      }

      &:nth-child(3n) {
        @media screen and (max-width: 460px) {
          order: 4;
        }
      }
    }
  }

  &-footnotes {
    display: block;
    font-size: 0.7rem;
    padding: 1rem 0;
    margin-top: 2rem;
    border-top: 2px solid var(--color-accent);
    line-height: 1.5;

    @media screen and (max-width: 460px) {
      order: 5;
    }

    ul {
      margin: 1rem 0;
      padding: 0;
      list-style: none;
    }
  }
}
</style>
