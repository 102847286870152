<template>
  <PageHeader />
  <router-view />
  <PageFooter />
</template>

<script>
import PageHeader from '@/components/PageHeader'
import PageFooter from '@/components/PageFooter'

export default {
  name: 'IglyRefundowane',
  components: {
    PageHeader,
    PageFooter,
  },
}
</script>

<style lang="scss">
@import url('@/style/_theme.scss');
</style>
