<template>
  <div class="page__description short--desc">
    <div class="page__description-column">
      <p class="page__description-column-text">
        Refundacja NFZ igieł PIC Insupen Original przysługuje pacjentom
        posiadającym aktualne ubezpieczenie zdrowotne na podstawie zlecenia
        wystawionego przez osobę uprawnioną m.in. przez lekarza specjalistę.
      </p>
    </div>

    <div class="page__description-column">
      <p class="page__description-column-text">
        Wskazaniami do
        <strong
          >refundacji NFZ igieł PIC Insupen Original<br />
          z 30% odpłatnością pacjenta</strong
        >
        jest cukrzyca wymagająca podawania insuliny. W ramach refundacji NFZ
        pacjentowi w ciągu roku przysługuje
        <strong>do 4 opakowań igieł</strong> (każde opakowanie zawiera 100
        igieł).
      </p>
    </div>

    <div class="page__description-table">
      <a href="tabela-refundacje-2024.png" target="_blank">
        <img src="tabela-refundacje-2024.png" alt="Tabela refundacji" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageLekarzeDesc',
}
</script>

<style lang="scss">
.page__description-table {
  margin: 3rem 0 2rem;

  @media screen and (max-width: 460px) {
    margin: 1rem -2rem 1rem;
  }
}
</style>
