<template>
  <div class="page__table">
    <div class="page__table-container">
      <div class="page__table-info">
        <p v-if="subtitle">
          Od 1 marca 2022 roku, diabetycy będą mogli skorzystać z refundacji na
          igły:<br />
          <strong>&mdash; PIC Insupen Original 0,25x5mm (31G)</strong><br />
          <strong>&mdash; PIC Insupen Original 0,30x8mm (30G)</strong><br />
        </p>
        <p>
          Wskazaniami do
          <strong>refundacji NFZ igieł z 30% odpłatnością</strong> pacjenta jest
          cukrzyca wymagająca podawania insuliny.<br />
          W ramach refundacji NFZ pacjentowi w ciągu roku przysługuje
          <strong>do 4 opakowań igieł</strong> (każde opakowanie zawiera 100
          igieł).
        </p>
      </div>
      <div class="page__table-image" v-if="showTable">
        <a href="tabela-refundacje-2024.png" target="_blank">
          <img src="tabela-refundacje-2024.png" alt="Tabela refundacji" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTable',
  props: {
    subtitle: {
      type: Boolean,
      default: true,
    },
    showTable: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss">
.page__table {
  background: var(--color-main);
  color: var(--color-bg);
  font-size: 1.21rem;
  padding: 5rem 0;
  line-height: 1.6;

  @media screen and (max-width: 460px) {
    padding: 3rem 0;
    font-size: 1rem;
  }

  &-container {
    margin: 0 auto;
    width: 100%;
    max-width: var(--max-width);
    padding: 0 2rem;

    @media screen and (max-width: 460px) {
      padding: 0 1.4rem 0 1.4rem;
      text-align: left;
    }
  }

  &-info {
    p {
      margin: 0 0 1rem;

      &:last-child {
        @media screen and (max-width: 460px) {
          padding: 1rem 1rem;
          margin: 0 -1rem;
        }

        br {
          @media screen and (max-width: 460px) {
            margin-bottom: 1rem;
          }
        }
      }
    }

    strong {
      font-weight: 600;
    }
  }

  &-image {
    margin: 2rem 0 0;

    img {
      border: 5px solid var(--color-bg);
    }
  }
}
</style>
