<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 149.569 51.935">
    <g transform="translate(-352.178 -29.703)">
      <rect
        width="4.473"
        height="31.267"
        transform="translate(352.178 29.703)"
        fill="#23408f"
      />
      <path
        d="M380.608,60.191h-3.9V43.273a2.225,2.225,0,0,0-2.613-2.524h-6.112a3.181,3.181,0,0,0-2.126.531,2.681,2.681,0,0,0-.575,1.993V60.191h-3.853V42.786q0-6.023,6.555-6.023h6.067q6.554,0,6.555,6.023Z"
        transform="translate(1.021 0.779)"
        fill="#23408f"
      />
      <path
        d="M400.117,40.66H388.375a1.7,1.7,0,0,0-1.081.461,1.544,1.544,0,0,0-.552,1.251v1.67a1.9,1.9,0,0,0,.6,1.295,1.948,1.948,0,0,0,1.483.637h5.757a5.423,5.423,0,0,1,3.942,1.556,5.129,5.129,0,0,1,1.595,3.82v3.688a5.245,5.245,0,0,1-1.41,3.554,4.5,4.5,0,0,1-3.524,1.6H382.889v-3.9h11.746a1.411,1.411,0,0,0,1.124-.557,2.826,2.826,0,0,0,.461-1.805V52.145a1.983,1.983,0,0,0-2.259-2.229h-5.225a6.1,6.1,0,0,1-4.429-1.545,4.432,4.432,0,0,1-1.417-3.31v-3a5.382,5.382,0,0,1,1.347-3.906,5.019,5.019,0,0,1,3.777-1.39h12.1Z"
        transform="translate(3.39 0.779)"
        fill="#23408f"
      />
      <path
        d="M421.847,54.168q0,6.023-6.555,6.023h-6.067q-6.554,0-6.555-6.023V36.763h3.853V53.77a2.353,2.353,0,0,0,.642,1.949,3.444,3.444,0,0,0,2.06.487h6.113a2.92,2.92,0,0,0,2.06-.554,2.764,2.764,0,0,0,.552-1.971V36.763h3.9Z"
        transform="translate(5.573 0.779)"
        fill="#23408f"
      />
      <path
        d="M440.351,53.991V42.83a3.684,3.684,0,0,0-.177-1.461q-.4-.708-1.817-.708H431.4q-2.745,0-2.744,2.392v10.5a2.792,2.792,0,0,0,.841,2.126,1.966,1.966,0,0,0,1.329.62h7.352a2.027,2.027,0,0,0,1.66-.62,2.579,2.579,0,0,0,.51-1.683m3.9-.31a6.462,6.462,0,0,1-1.727,4.782,5.73,5.73,0,0,1-4.208,1.728H428.66v7.617h-3.853V42.7a5.8,5.8,0,0,1,1.617-4.363,6.11,6.11,0,0,1,4.45-1.572h7.263a6.256,6.256,0,0,1,4.739,1.595,5.625,5.625,0,0,1,1.372,4.074Z"
        transform="translate(8.017 0.779)"
        fill="#23408f"
      />
      <path
        d="M462.363,43.033a2.531,2.531,0,0,0-.442-1.582,2.326,2.326,0,0,0-1.946-.791h-6.286q-2.653,0-2.655,2.966v4.692Zm4.126,17.158H453.515a6.313,6.313,0,0,1-4.607-1.683,5.962,5.962,0,0,1-1.726-4.473v-10.5q0-6.776,7.174-6.776h5.757a6.184,6.184,0,0,1,4.495,1.572,5.865,5.865,0,0,1,1.617,4.406v2.79l-15.191,7.088v.8a3.178,3.178,0,0,0,.621,2.259,2.871,2.871,0,0,0,2.081.62h12.754Z"
        transform="translate(10.487 0.779)"
        fill="#23408f"
      />
      <path
        d="M487.9,60.191H484V43.273a2.225,2.225,0,0,0-2.613-2.524h-6.112a3.182,3.182,0,0,0-2.126.531,2.677,2.677,0,0,0-.575,1.993V60.191h-3.853V42.786q0-6.023,6.555-6.023h6.067q6.554,0,6.555,6.023Z"
        transform="translate(12.864 0.779)"
        fill="#23408f"
      />
      <path
        d="M429.324,74.914V71.071a1.121,1.121,0,0,0-.258-.853,1.283,1.283,0,0,0-.867-.233h-1.306a1.312,1.312,0,0,0-.886.239,1.113,1.113,0,0,0-.263.847v3.82a1.119,1.119,0,0,0,.258.853,1.328,1.328,0,0,0,.892.233H428.2a1.4,1.4,0,0,0,.922-.2,1.267,1.267,0,0,0,.2-.859m1.206-.083a2.376,2.376,0,0,1-.6,1.8,2.789,2.789,0,0,1-1.946.561h-.906a2.794,2.794,0,0,1-1.934-.561,2.349,2.349,0,0,1-.609-1.8v-3.7a2.378,2.378,0,0,1,.6-1.8,2.789,2.789,0,0,1,1.946-.561h.906a2.793,2.793,0,0,1,1.934.561,2.35,2.35,0,0,1,.608,1.8Z"
        transform="translate(7.987 4.312)"
        fill="#23408f"
      />
      <path
        d="M440.1,77.195h-1.587l-3.068-3.581a.573.573,0,0,1-.119-.374.807.807,0,0,1,.2-.554.926.926,0,0,1,.72-.289h1.82a.8.8,0,0,0,.569-.2.65.65,0,0,0,.21-.5v-.931a.855.855,0,0,0-.155-.55.711.711,0,0,0-.587-.239h-2.826v7.21h-1.206V68.768h4.214a1.685,1.685,0,0,1,1.337.561,1.777,1.777,0,0,1,.429,1.194v1.457a1.551,1.551,0,0,1-.513,1.134,1.536,1.536,0,0,1-1.075.5h-1.431Z"
        transform="translate(9.039 4.312)"
        fill="#23408f"
      />
      <rect
        width="1.206"
        height="8.427"
        transform="translate(453.447 73.08)"
        fill="#23408f"
      />
      <path
        d="M454.56,74.986a2.275,2.275,0,0,1-.543,1.653,2.083,2.083,0,0,1-1.558.555h-1.671a2.17,2.17,0,0,1-1.641-.58,2.207,2.207,0,0,1-.554-1.593V70.988a1.962,1.962,0,0,1,2.22-2.22h3.2v1.217h-3.294a1.035,1.035,0,0,0-.741.191,1.093,1.093,0,0,0-.179.728v4.189a.844.844,0,0,0,.251.644,1.017,1.017,0,0,0,.728.239h1.682a.914.914,0,0,0,.656-.227.828.828,0,0,0,.239-.633V72.4h1.206Z"
        transform="translate(10.642 4.312)"
        fill="#23408f"
      />
      <rect
        width="1.206"
        height="8.427"
        transform="translate(469.786 73.08)"
        fill="#23408f"
      />
      <path
        d="M469.169,76.2a1.164,1.164,0,0,1-.285.835.91.91,0,0,1-.687.3,1.137,1.137,0,0,1-.67-.222,1.443,1.443,0,0,1-.493-.654L464.513,70.4v6.8h-1.206V69.957a1.362,1.362,0,0,1,.288-.93.92.92,0,0,1,.719-.322,1.131,1.131,0,0,1,.652.221,1.371,1.371,0,0,1,.483.626l2.513,6.181V68.775h1.207Z"
        transform="translate(12.266 4.305)"
        fill="#23408f"
      />
      <path
        d="M477.088,74.23,475.7,70.383l-1.368,3.847Zm2.354,2.971-1.282,0-.632-1.752h-3.639l-.635,1.752-1.284,0,2.769-7.638c.214-.573.542-.859.979-.859s.76.286.967.859Z"
        transform="translate(13.223 4.305)"
        fill="#23408f"
      />
      <path
        d="M487.41,77.195H484.27a2.252,2.252,0,0,1-1.622-.549,2.066,2.066,0,0,1-.574-1.565V68.768h1.206v6.3a.924.924,0,0,0,.221.7,1.066,1.066,0,0,0,.746.21h3.162Z"
        transform="translate(14.338 4.312)"
        fill="#23408f"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LogoInsupen',
}
</script>
