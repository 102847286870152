<template>
  <div class="page__description">
    <div class="page__description-text">
      Cukrzyca to przewlekła choroba metaboliczna spowodowana zaburzeniem
      wydzielania lub działania insuliny - hormonu produkowanego przez trzustkę.
      Cukrzyce dzielimy na dwa typy:
    </div>

    <div class="page__description-column">
      <div class="page__description-column-head">
        <span>Cukrzyca Typu 1</span>
        <span>DZIĘCIĘCA (genetyczna)</span>
      </div>

      <ul class="page__description-column-list">
        <li>
          organizm nie produkuje w ogóle insuliny<br />
          – insulinozależność
        </li>
        <li>od 5% do 10% diagnozowanych przypadków</li>
      </ul>

      <div class="page__description-column-info">
        Typ 1.5 - LADA: Utajona cukrzyca autoimmunologiczna u dorosłych. Jest to
        forma cukrzycy typu 1, która występuje w wieku dorosłym, często z
        wolniejszym przebiegiem niż cukrzyca typu 1 rozpoznawana u nieletnich.
      </div>
    </div>

    <div class="page__description-column">
      <div class="page__description-column-head">
        <span>Cukrzyca Typu 2</span>
        <span>WIEK I ZŁE NAWYKI</span>
      </div>

      <ul class="page__description-column-list">
        <li>organizm nie produkuje insuliny/źle gospodaruje insuliną</li>
        <li>
          od 90% do 95% diagnozowanych przypadków cukrzycy z czego około 20%
          jest insulinozależnych
        </li>
      </ul>

      <div class="page__description-column-info">
        Dorośli z LADA mogą początkowo zostać błędnie zdiagnozowani jako chorzy
        na cukrzycę typu 2 na podstawie ich wieku, szczególnie jeśli mają
        czynniki ryzyka cukrzycy typu 2.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageDescription',
}
</script>

<style lang="scss">
.page__description {
  padding: 0 2rem;
  max-width: var(--max-width);
  margin: auto;
  line-height: 1.6;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 1rem;

  &-text {
    flex: 1 0 100%;
    text-align: center;
    padding: 1rem 3rem 2rem;
    color: var(--color-main);
    font-size: 1.1rem;

    @media screen and (max-width: 460px) {
      padding: 1rem 0;
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  &-column {
    flex: 1;
    max-width: 47%;

    @media screen and (max-width: 460px) {
      max-width: 100%;
      margin-bottom: 2rem;
    }

    &-head {
      display: block;
      color: var(--color-main);
      padding: 1rem 0;
      border-bottom: 4px solid var(--color-accent);
      margin-bottom: 2rem;

      span {
        display: block;
        font-size: 1.894rem;
        font-weight: 700;
        line-height: 1.4;

        @media screen and (max-width: 460px) {
          font-size: 1.75rem;
        }

        & + span {
          font-size: 1.578rem;

          @media screen and (max-width: 460px) {
            font-size: 1.3rem;
          }
        }
      }
    }

    &-list {
      margin: 0;
      padding: 0;
      min-height: 10rem;

      li {
        display: block;
        padding: 0 0 1rem 1.5rem;
        font-weight: 500;
        font-size: 1.21rem;
        line-height: 1.5;
        color: var(--color-main);
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0.75rem;
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background: var(--color-main);
        }
      }
    }
  }
}
</style>
