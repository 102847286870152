<template>
  <div class="page__instructions">
    <div class="page__instructions-container">
      <div class="page__instructions-head">Właściwa technika wstrzykiwania</div>
      <div class="page__instructions-subtitle">
        Aby zoptymalizować kontrolę glikemii i zapobiec nieprzyjemnym
        powikłaniom
      </div>

      <div class="page__instructions-steps">
        <div class="page__instructions-steps-item">
          <div class="page__instructions-steps-title">
            <span>1</span>
            <div>Zawsze wybieraj inne miejsce do iniekcji</div>
          </div>
          <div class="page__instructions-steps-details">
            <p>
              <strong
                >Rotacja wstrzyknięć pomiędzy miejscami: jeden kwadrant na
                tydzień.</strong
              >

              Podzielić miejsca wstrzyknięć na kwadranty (lub połówki, w
              przypadku pośladków lub uda) i stosować jeden kwadrant tygodniowo,
              przechodząc z jednego kwadrantu do innego w spójny sposób (np.
              zgodnie z ruchem wskazówek zegara).
            </p>
            <div class="page__instructions-steps-details-icon">
              <img :src="require('@/assets/steps-icon-1.png')" alt="PIC" />
            </div>
          </div>

          <div class="page__instructions-steps-details">
            <div class="page__instructions-steps-details-icon">
              <img :src="require('@/assets/steps-icon-2.png')" alt="PIC" />
            </div>
            <p>
              <strong
                >Obracać wstrzyknięcia w obrębie miejsc, przesuwając się o 1
                cm.</strong
              >

              Wstrzyknięcia powinny być rotowane systematycznie, w odstępie co
              najmniej 1 cm (w przybliżeniu szerokość dorosłego palca), aby
              uniknąć powtarzającego się miejscowego urazu tkanek.
            </p>
          </div>
        </div>

        <div class="page__instructions-steps-item">
          <div class="page__instructions-steps-title">
            <span>2</span>
            <div>
              Podczas wstrzykiwania należy unikać zginania/zaginania pomiędzy
              penem a igłą oraz pomiędzy igłą a skórą, aby uniknąć ewentualnego
              złamania igły.
            </div>
          </div>
          <div class="page__instructions-steps-icons">
            <div class="page__instructions-steps-details-icon">
              <img :src="require('@/assets/steps-icon-3.png')" alt="PIC" />
            </div>
            <div class="page__instructions-steps-details-icon">
              <img :src="require('@/assets/steps-icon-4.png')" alt="PIC" />
            </div>
            <div class="page__instructions-steps-details-icon">
              <img :src="require('@/assets/steps-icon-5.png')" alt="PIC" />
            </div>
            <div class="page__instructions-steps-details-icon">
              <img :src="require('@/assets/steps-icon-6.png')" alt="PIC" />
            </div>
          </div>
        </div>

        <div class="page__instructions-steps-item icons--inline">
          <div class="page__instructions-steps-title">
            <span>3</span>
            <div>
              Unikać wciskania piasty igły głęboko w skórę, ponieważ zwiększa to
              ryzyko wstrzyknięcia domięśniowego.
            </div>
          </div>
          <div class="page__instructions-steps-icons">
            <div class="page__instructions-steps-details-icon">
              <img :src="require('@/assets/steps-icon-7.png')" alt="PIC" />
            </div>
          </div>
        </div>

        <div class="page__instructions-steps-item icons--inline">
          <div class="page__instructions-steps-title">
            <span>4</span>
            <div>
              Jeśli konieczne jest pobranie fałdu skórnego, delikatnie
              uszczypnij skórę używając kciuka i palca wskazującego.
            </div>
          </div>
          <div class="page__instructions-steps-icons">
            <div class="page__instructions-steps-details-icon">
              <img :src="require('@/assets/steps-icon-8.png')" alt="PIC" />
            </div>
            <div class="page__instructions-steps-details-icon">
              <img :src="require('@/assets/steps-icon-9.png')" alt="PIC" />
            </div>
            <div class="page__instructions-steps-details-icon">
              <img :src="require('@/assets/steps-icon-10.png')" alt="PIC" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageInstructions',
}
</script>

<style lang="scss">
.page__instructions {
  background: var(--color-main);
  color: var(--color-bg);
  font-size: 1.21rem;
  padding: 5rem 0;
  line-height: 1.6;

  @media screen and (max-width: 460px) {
    padding: 4rem 0;
  }

  &-container {
    margin: 0 auto;
    width: 100%;
    max-width: var(--max-width);
    padding: 0 2rem;

    @media screen and (max-width: 460px) {
      padding: 0 1.5rem;
    }
  }

  &-head {
    font-size: 1.894rem;
    font-weight: 600;
    display: block;
    text-align: center;

    @media screen and (max-width: 460px) {
      line-height: 1.2;
      padding-bottom: 1rem;
    }
  }
  &-subtitle {
    font-size: 1rem;
    font-weight: 400;
    display: block;
    text-align: center;
  }

  &-steps {
    display: block;
    padding: 4rem 0 0;

    &-item {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 4rem;

      @media screen and (max-width: 460px) {
        padding-bottom: 1rem;
      }

      &.icons--inline {
        margin-top: 2rem;
        @media screen and (max-width: 460px) {
          flex-direction: column;
        }

        .page__instructions-steps-title {
          max-width: 60%;
          padding: 0;

          @media screen and (max-width: 460px) {
            max-width: 100%;
          }

          div {
            max-width: unset;
          }
        }
        .page__instructions-steps-icons {
          flex: 1;
          max-width: 40%;

          @media screen and (max-width: 460px) {
            max-width: 100%;
            flex-direction: row;
            margin-bottom: 3rem;
          }

          .page__instructions-steps-details-icon {
            @media screen and (max-width: 460px) {
              max-height: 7rem;
            }

            img {
              @media screen and (max-width: 460px) {
                max-height: unset;
                max-width: 100%;
              }
            }
          }
        }
      }
    }

    &-title {
      flex: 1 100%;
      display: flex;
      align-items: center;
      font-weight: 600;
      padding-bottom: 2rem;

      @media screen and (max-width: 460px) {
        align-items: flex-start;
        padding-top: 1rem;
        padding-bottom: 2rem;
      }

      span {
        flex: 1 0;
        border: 3px solid var(--color-bg);
        border-radius: 100%;
        margin-right: 1.5rem;
        max-width: 2.5rem;
        min-width: 2.5rem;
        height: 2.5rem;
        display: flex;
        line-height: 1;
        justify-content: center;
        align-items: center;
      }
      div {
        flex: 1 auto;
        line-height: 1.3;
        font-size: 1.263rem;
        max-width: 75%;

        @media screen and (max-width: 460px) {
          max-width: 100%;
          font-size: 1.15rem;
        }
      }
    }

    &-details {
      max-width: 50%;
      display: flex;

      @media screen and (max-width: 460px) {
        max-width: 100%;
        flex-direction: column;
        align-items: center;
      }

      p {
        flex: 1;
        margin: 0 0 2rem;
        font-size: 0.894rem;
        padding-right: 2rem;

        @media screen and (max-width: 460px) {
          order: 2;
          max-width: 100%;
          padding: 0;
          margin: 2rem 0 3rem;
        }

        strong {
          font-weight: 600;
          display: block;
          padding-bottom: 1rem;
          font-size: 1rem;

          @media screen and (max-width: 460px) {
          }
        }
      }

      &-icon {
        flex: 1;
        max-width: 9rem;
        margin: 0 1rem;

        @media screen and (max-width: 460px) {
          order: 1;
          max-width: 100%;
        }
      }
    }

    &-icons {
      display: flex;
      width: 100%;
      justify-content: space-between;

      @media screen and (max-width: 460px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 1rem 0;
      }

      .page__instructions-steps-details-icon {
        max-width: unset;
        max-height: 8rem;
        margin: auto;
        flex: auto;

        @media screen and (max-width: 460px) {
          margin: 0.5rem 0;
        }

        img {
          max-width: unset;
          max-height: 100%;
        }
      }
    }
  }
}
</style>
