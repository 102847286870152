<template>
  <div class="page__boxes">
    <div class="page__boxes-item">
      <div class="page__boxes-button" @click="showOverlay(1)">
        <span>
          Co to jest stan<br />
          przedcukrzycowy?
        </span>
      </div>
      <div
        class="page__boxes-details"
        :class="{ 'is--active': active === 1 }"
        @click.self="hideOverlay()"
      >
        <div class="page__boxes-details-container">
          <div class="page__boxes-details-close" @click="hideOverlay()" />
          <div class="page__boxes-details-title">Stan przedcukrzycowy</div>
          <div class="page__boxes-details-info">
            <p>
              Poziom glukozy we krwi jest wyższy niż powinien, ale nie
              wystarczający do rozpoznania cukrzycy. Określany jako upośledzony
              stan glukozy na czczo. Osoby z cukrzycą typy 2 prawie zawsze miały
              stan przedcukrzycowy jako pierwszy, ale zwykle nie powoduje to
              objawów.
            </p>
            <p>
              <b>Ciążowa</b> - rozwija się w czasie ciąży i pojawia się
              normalnie między 24 a 28 tygodniem; około 4% wszystkich ciężarnych
              zapada na cukrzycę ciążową. To wielkie ryzyko dla cukrzycy typu 2.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="page__boxes-item">
      <div class="page__boxes-button" @click="showOverlay(2)">
        <span>
          Przyczyny złego<br />
          wyrównania<br />
          glikemii
        </span>
      </div>
      <div
        class="page__boxes-details"
        :class="{ 'is--active': active === 2 }"
        @click.self="hideOverlay()"
      >
        <div class="page__boxes-details-container">
          <div class="page__boxes-details-close" @click="hideOverlay()" />
          <div class="page__boxes-details-title">
            Przyczyny złego wyrównania glikemii
          </div>
          <div class="page__boxes-details-info">
            <p>
              Przed rozpoczęciem insulinoterapii ważne jest, żeby wykluczyć
              ewentualne przyczyny złego wyrównania glikemii u osób chorych.
              Należą do nich:
            </p>
            <ul>
              <li>Zła dieta</li>
              <li>Brak aktywności fizycznej</li>
              <li>
                Współistniejące choroby (choroby wątroby, zakażenia dróg
                oddechowych)
              </li>
              <li>
                Niestosowanie zaleceń lekarza (zwłaszcza przy lekach doustnych)
              </li>
              <li>Stres</li>
              <li>
                Jeśli nadal leczenie jest nieskuteczne nawet po zminimalizowaniu
                lub wyeliminowaniu powyższych czynników, lekarz może zalecić
                insulinoterapię.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="page__boxes-item">
      <div class="page__boxes-button" @click="showOverlay(3)">
        <span>
          Co należy wiedzieć<br />
          o insulinoterapii?
        </span>
      </div>
      <div
        class="page__boxes-details"
        :class="{ 'is--active': active === 3 }"
        @click.self="hideOverlay()"
      >
        <div class="page__boxes-details-container">
          <div class="page__boxes-details-close" @click="hideOverlay()" />
          <div class="page__boxes-details-title">
            Co należy wiedzieć o insulinoterapii?
          </div>
          <div class="page__boxes-details-info">
            <p>
              Insulina jest hormonem niezbędnym do prawidłowego metabolizmu
              węglowodanów, białek i tłuszczów. Chorzy na cukrzycę typu 1
              wytwarzają zbyt małe ilości tego hormonu, dlatego ich życie
              uzależnione jest od insuliny dostarczanej z zewnątrz.
            </p>
            <p>
              <strong>
                Każdego dnia osoby cierpiące na cukrzycę Typu 1 muszą powtórzyć
                poniższe czynności<br />
                4 razy albo więcej:
              </strong>
            </p>
            <ul>
              <li>Nakłuć palec aby uzyskać kroplę krewi do testu</li>
              <li>Dokonać analizy krwi za pomocą glukometru</li>
              <li>
                Wykonać zastrzyk z insuliny za pomocą strzykawki, pena lub pompy
                insulinowej
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="page__boxes-item">
      <div class="page__boxes-button" @click="showOverlay(4)">
        <span>
          Cieńsza igła<br />
          - mniej bolesny zastrzyk
        </span>
      </div>

      <div
        class="page__boxes-details"
        :class="{ 'is--active': active === 4 }"
        @click.self="hideOverlay()"
      >
        <div class="page__boxes-details-container">
          <div class="page__boxes-details-close" @click="hideOverlay()" />
          <div class="page__boxes-details-title">
            Cieńsza igła - mniej bolesny zastrzyk
          </div>
          <div class="page__boxes-details-info">
            <p class="is--column">
              Występuje istotna korelacja między średnicą igły, a
              częstotliwością bolesnych wstrzyknięć.<br />
              <br />
              Ból i dyskomfort mogą powodować rozdrażnienie i pomijanie
              zastrzyków oraz w rezultacie pogorszenie leczenia. Zmniejszenie
              bólu i dyskomfortu może przynieść korzyści kliniczne, ponieważ
              może zwiększyć się przestrzeganie zaleceń
              insulinoterapii<sup>*</sup>.

              <span class="page__boxes-details-footnotes">
                <sup>*</sup>Pain following controlled cutaneous insertion of
                needles with different diameters. Lars Arendt- Nielsen, Henrik
                Egevist &amp; Peter Bjerring. Somatosensory and Motor Reserch,
                March/June 2006; 23(1/2) : 37-43 (from page 3-4)
              </span>
            </p>
            <p class="is--column">
              <img
                :src="require('@/assets/procent-bolesnych-zastrzykow.png')"
                alt="Procent bolesnych zastrzyków"
              />
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="page__boxes-item">
      <div class="page__boxes-button" @click="showOverlay(5)">
        <span>
          Zalety cienkich<br />
          ścianek
        </span>
      </div>

      <div
        class="page__boxes-details"
        :class="{ 'is--active': active === 5 }"
        @click.self="hideOverlay()"
      >
        <div class="page__boxes-details-container">
          <div class="page__boxes-details-close" @click="hideOverlay()" />
          <div class="page__boxes-details-title">Zalety cienkich ścianek</div>
          <div class="page__boxes-details-info">
            <div class="is--column">
              Grubość skóry właściwej jest związana z BMI, wiekiem, płcią oraz
              etnicznym pochodzeniem i waha się pomiędzy 2.0 do 2.5 mm.
              Zastrzyki powinny być robione do podskórnej tkanki tłuszczowej.
              Należy unikać zastrzyków domięśniowych ponieważ może to
              doprowadzić do szybszego wchłaniania insuliny i do
              hipoglikemii<sup>*</sup>.
              <br /><br />
              <strong>Zalety cienkich ścianek:</strong>
              <ul>
                <li>mniejszy ból</li>
                <li>mniejsze krwawienia</li>
                <li>mniej podrażniona skóra</li>
                <li>mniejszy strumień przepływu</li>
                <li>mniej przepływu zwrotnego/cofania się</li>
                <li>mniejsza niedrożność igły</li>
              </ul>

              <span class="page__boxes-details-footnotes">
                <sup>*</sup>New Insulin Delivery Recommendations. Anders H.
                Frid, MD; Gillian Kreugel, DSN; Giorgio Grassi, MD; Serge
                Halimi, MD; Debbie Hicks, DSN; Laurence J. Hirsch, MD; Mike J.
                Smith, DSN; Regine Wellhoener, MD; Bruce W. Bode, MD; Irl B.
                Hirsch, D; Sanjay Kalra, MD; Linong Ji, MD; and Kenneth W.
                Strauss, MD. Mayo Clin Proc. n September 2016;91(9):1231-1255.
              </span>
            </div>
            <div class="is--column">
              <img
                :src="require('@/assets/cienkie-scianki.png')"
                alt="Zalety cienkich ścianek"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="page__boxes-item">
      <div class="page__boxes-button" @click="showOverlay(6)">
        <span>
          Strach i ból związany<br />
          z zastrzykami
        </span>
      </div>

      <div
        class="page__boxes-details"
        :class="{ 'is--active': active === 6 }"
        @click.self="hideOverlay()"
      >
        <div class="page__boxes-details-container">
          <div class="page__boxes-details-close" @click="hideOverlay()" />
          <div class="page__boxes-details-title">
            Strach i ból związany z zastrzykami
          </div>
          <div class="page__boxes-details-info">
            <ul>
              <li>
                94% użytkowników insuliny ma objawy rozdrażnienia, niepokoju lub
                fobii związanej z robieniem zastrzyków<br />
                i widokiem krwi<sup>(1)</sup>
              </li>
              <li>
                33% użytkowników insuliny boi się zastrzyków<sup>(2)</sup>
              </li>
              <li>
                75% nowo zdiagnozowanych dzieci powyżej 9 roku życia boi się
                zastrzyków<sup>(3)</sup>
              </li>
              <li>
                47% diabetyków twierdzi, że chętniej przestrzegaliby zalecanego
                procesu podawania insuliny<sup>(4)</sup>
              </li>
            </ul>
            <span class="page__boxes-details-footnotes">
              1. Berlin I, Bisserbe JC, Eiber R, Balssa, N, Sachon C, Bosquet F,
              Grimaldi, A: Phobic symptoms, particularly the fear of blood and
              injury, are associated with poor glycemic control in type 1
              diabetic adults. Diabetes Care 1997;20:176–178
              <br />
              2. Peyrot M, Rubin RR, Kruger DF, Travis LB: Correlates of insulin
              injection omission. Diabetes Care 2010;33:240-245
              <br />
              3. Howe CJ, Ratcliffe SJ, Tuttle A, Dougherty S, Lipman TH: Needle
              anxiety in children with type 1 diabetes and their mothers. AM J
              Matern Child Nurs 2010;36:25-31
              <br />
              4. AADE Injection Impact Survey, 2007 . American Diabetes
              Association: Continuous subcutaneous insulin infusion (Position
              Statement). Diabetes Care 2002; 25 (supl. 1): S116.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageBoxes',
  data() {
    return {
      active: null,
    }
  },

  methods: {
    showOverlay(item) {
      this.active = item
    },

    hideOverlay() {
      this.active = null
    },
  },
}
</script>

<style lang="scss">
.page__boxes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: var(--max-width);
  margin: 4rem auto;
  align-items: stretch;
  align-content: stretch;
  padding: 0 2rem;

  &-item {
    flex: 1 0 32%;
    max-width: 32%;
    margin-bottom: 2rem;

    @media screen and (max-width: 460px) {
      flex: 1 0 100%;
      max-width: 100%;
    }
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 15rem;
    border: 1px solid rgba(5, 64, 144, 0.1);
    border-radius: 13px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    font-weight: 700;
    text-align: center;
    color: var(--color-main);
    padding: 3rem;
    font-size: 1.5rem;
    line-height: 1.3;
    cursor: pointer;
    transition: var(--transition);

    @media screen and (max-width: 460px) {
      padding: 2rem;
      font-size: 1.3rem;
      min-height: 10rem;
    }

    &:hover {
      background: var(--color-main);
      color: var(--color-bg);
    }
  }

  &-details {
    position: fixed;
    top: 0%;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    z-index: -1;
    transition: var(--transition);
    transition-delay: 0.1s;

    &.is--active {
      z-index: 9999;
      opacity: 1;
    }

    &-info {
      display: flex;
      flex-wrap: wrap;
      font-size: 0.894rem;

      p {
        flex: 1 0 100%;
        margin: 0 0 1rem;

        &.is--column {
          max-width: 50%;
          padding: 0 1rem 0 0;
          margin-bottom: 0;

          @media screen and (max-width: 460px) {
            max-width: 100%;
          }

          + p.is--column {
            max-width: 50%;
            padding: 0 0 0 0.5rem;

            @media screen and (max-width: 460px) {
              max-width: 100%;
            }
          }
        }
      }

      ul {
        margin: 0.5rem 0 0;

        @media screen and (max-width: 460px) {
          padding: 0 1rem;
        }

        li {
          line-height: 1.3;
          padding-bottom: 0.5rem;

          br {
            @media screen and (max-width: 460px) {
              display: none;
            }
          }
        }

        sup {
          font-size: 70%;
          top: -5px;
          vertical-align: baseline;
          position: relative;
        }
      }

      div {
        flex: 1 0 100%;
        margin: 0 0 1rem;

        &.is--column {
          max-width: 60%;
          padding: 0 1rem 0 0;
          font-size: 0.894rem;
          margin-bottom: 0;

          @media screen and (max-width: 460px) {
            max-width: 100%;
          }

          + div.is--column {
            max-width: 40%;
            padding: 0 0 0 0.5rem;

            @media screen and (max-width: 460px) {
              max-width: 100%;
            }
          }
        }
      }
    }

    &-footnotes {
      display: block;
      font-size: 0.7rem;
      padding-top: 0.5rem;
      margin-top: 1rem;
      border-top: 2px solid var(--color-accent);
      line-height: 1.5;
    }

    &-container {
      max-width: calc(var(--max-width) - 20rem);
      display: block;
      background: var(--color-bg);
      padding: 2.25rem;
      box-shadow: 0 0 80px 30px rgba(0, 0, 0, 0.5);
      border-radius: 12px;
      line-height: 1.6;
      position: relative;

      @media screen and (max-width: 460px) {
        padding: 1.5rem;
        max-width: 95%;
        max-height: 90vh;
        overflow: auto;
        padding-right: 2rem;
      }
    }

    &-title {
      font-weight: 600;
      color: var(--color-main);
      font-size: 1.21rem;
      display: block;
      padding-bottom: 1rem;

      @media screen and (max-width: 460px) {
        font-size: 1rem;
        line-height: 1.1;
        max-width: 90%;
      }
    }

    &-close {
      position: absolute;
      width: 30px;
      height: 30px;
      top: 1.3rem;
      right: 1.3rem;
      cursor: pointer;

      @media screen and (max-width: 460px) {
        height: 16px;
        width: 16px;
      }

      &::after,
      &::before {
        content: '';
        width: 3px;
        height: 30px;
        background: var(--color-text);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: rotate(-45deg) translate3d(11px, -11px, 0);

        @media screen and (max-width: 460px) {
          transform: rotate(-45deg) translate3d(5px, -5px, 0);
        }
      }
      &::before {
        transform: rotate(45deg) translate3d(-11px, -11px, 0);

        @media screen and (max-width: 460px) {
          transform: rotate(45deg) translate3d(-5px, -5px, 0);
        }
      }
    }
  }
}
</style>
